import axios from "axios"
import {Link} from "gatsby"
import React from "react"
import {FieldControl, FieldGroup, FormBuilder} from "react-reactive-form"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import styled from "styled-components"
import theme from "assets/styles/theme"
import Loader from "components/Loader"
import Pagination from "components/Pagination"
import SectionHeader from "components/SectionHeader"
import SwipeableViews from "react-swipeable-views"
import TextInput from "components/Forms/TextInput"
import TopBar from "components/TopBar"
import {checkNotificationPermission} from "services/pushManager"
import FavButton from "../FavButton";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px 15px 90px 15px;
  display: flex;
  flex-direction: column;
`

const KnowledgeSection = styled.div`

`

const KnowledgeLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
`

const KnowledgeWrapper = styled.div`
  width: 100%;
  background: url(${({image}) => (image ? image : "")}) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 250px;
  margin-bottom: 2rem;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  font-size: 2.3rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:hover, &:focus {
    text-decoration: none;
  }
`

const KnowledgeCategories = styled.div`
  position: absolute;
  font-size: 1.3rem;
  display: flex;
  width: 100%;
  left: 0;
  padding: 1.5rem;
  top: 0;
  flex-wrap: wrap;
`

const CategoryName = styled.div`
  padding: 0.7rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 15px;
  background-color: ${theme.color.mainBlueColor};
  box-shadow: 0 0 30px 0 rgba(0, 48, 255, 0.8);
`

const KnowledgeTitle = styled.p`
  position: relative;
  margin: 1rem 0;
`

const StyledTabs = styled(Tabs)`
  margin: 2rem 0;

  .MuiTabs-indicator {
    background-color: ${theme.color.mainBlueColor};
  }
`

const StyledTab = styled(Tab)`
  font-size: 1.3rem !important;
  min-width: 50% !important;
  text-transform: none !important;
  opacity: 1 !important;
  font-family: inherit !important;
  border-bottom: 2px solid !important;

  &.Mui-selected {
    color: ${theme.color.mainBlueColor} !important;
    font-weight: bold !important;
  }
`

class KnowledgePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeCategoryIndex: 0,
      loading: true,
      error: false,
      fetchingDone: false,
      categories: {
        news: {
          key: 'news',
          label: 'Aktualności',
          items: [],
        },
        knowledges: {
          key: 'knowledges',
          label: 'Wiedza barmańska',
          items: [],
        }
      }
    }

    this.toggleFav = this.toggleFav.bind(this)
  }

  searchForm = FormBuilder.group({
    search: [""],
  })

  componentDidMount() {
    this.fetchItems(this.state.categories.news.key)
    this.fetchItems(this.state.categories.knowledges.key)

    setTimeout(() => {
      checkNotificationPermission(this.props.userProfile.accessToken)
    }, 3000)

    let activeCategoryIndex = window.history.state.activeCategoryIndex
    if (activeCategoryIndex) {
      this.setState({
        activeCategoryIndex: activeCategoryIndex,
      })
      window.history.state.activeCategoryIndex = null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
      this.fetchItems(this.state.categories.news.key)
      this.fetchItems(this.state.categories.knowledges.key)
    }
  }

  fetchItems = (entity, pageOfItems = 1, accessToken = this.props.userProfile.accessToken) => {

    if (accessToken && entity) {
      let params = {
        "favourites": 1,
        "access-token": accessToken,
        "sort": "created_at",
      }

      if (pageOfItems) {
        params.page = pageOfItems
        this.setState({loading: true})
      }

      return axios
        .get(`${process.env.API_URL}/${entity}`, {params})
        .then(resp => {
          this.setState(state => {
            return {
              ...state,
              loading: false,
              fetchingDone: true,
              categories: {
                ...state.categories,
                [entity]: {
                  ...state.categories[entity],
                  ...resp.data
                },
              }
            }
          })
        })
        .then(() => {
          if (pageOfItems) {
            this.forceUpdate()
            this.setState({loading: false})
            window.scrollTo(0, 0)
          }
        })
        .catch(error => {
          this.setState({loading: false, error: error})
        })
    }
    return null
  }

  onChangePage = (pageOfItems, forElement) => {
    this.fetchItems(forElement, pageOfItems)
  }

  handleTabChange = (event, value) => {
    this.setState({
      activeCategoryIndex: value,
    })
  }

  handleTabChangeIndex = activeCategoryIndex => {
    this.setState({
      activeCategoryIndex,
    })
  }

  toggleFav = (ev) => {
    const payload = JSON.parse(ev.currentTarget.value)
    const {id, catId} = payload

    this.setState({loading: true})

    axios({
      method: 'delete',
      url: `${process.env.API_URL}/${catId}/${id}/favourite`,
      params: {"access-token": this.props.userProfile.accessToken}
    })
      .then(response => {
        const category = this.state.categories[catId]
        const index = category.items.findIndex((el) => el.articleId === id);
        const newItems = [...this.state.categories[catId].items]

        newItems.splice(index,1)

        this.setState(state => {
          return {
            ...state,
            loading: false,
            fetchingDone: true,
            categories: {
              ...state.categories,
              [catId]: {
                ...state.categories[catId],
                items: newItems
              },
            }
          }
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({loading: false, error: error})
      })
  }

  render() {
    const {
      loading,
      activeCategoryIndex,
      categories,
      fetchingDone
    } = this.state
    return (
      <Wrapper>
        <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
        <SectionHeader header="Ulubione"/>

        {loading ? <Loader/> : null}

        {fetchingDone &&
        <div>
          <StyledTabs
            value={activeCategoryIndex}
            variant="scrollable"
            onChange={this.handleTabChange}
          >
            {Object.values(categories).map(cat => {
              return <StyledTab key={cat.key} label={cat.label}/>
            })}
          </StyledTabs>

          <SwipeableViews
            index={activeCategoryIndex}
            onChangeIndex={this.handleTabChangeIndex}
          >
            {Object.values(categories).map(category => {
              const itemLink = category.key === categories.news.key ? "news-post" : 'knowledge-post'
              return (
                <KnowledgeSection key={category.key}>
                  {category.items.map(item => (
                    <KnowledgeWrapper
                      key={item.articleId}
                      image={item.imageUrl}
                      state={{activeCategoryIndex: activeCategoryIndex}}
                    >
                      <FavButton
                        favorite={item.favourite}
                        value={JSON.stringify({
                          id: item.articleId,
                          fav: item.favourite,
                          catId: category.key
                        })}
                        onClick={(ev) => this.toggleFav(ev)}
                      />

                      <KnowledgeLink
                        to={`/app/${itemLink}/${item.articleId}/`}>
                        <KnowledgeCategories>
                          {item.categories &&
                          item.categories.map(category => (
                            <CategoryName
                              key={category.categoryId}>{category.name}</CategoryName>
                          ))
                          }
                        </KnowledgeCategories>

                        <KnowledgeTitle>{item.title}</KnowledgeTitle>
                      </KnowledgeLink>

                    </KnowledgeWrapper>
                  ))}

                  {category.items.length === 0 &&
                  <div>Obecnie w tej kategorii nie ma żadnych artykułów. Sprawdź
                    za jakiś czas.</div>
                  }

                  {category.pagination && category.pagination.totalCount > category.pagination.perPage && <Pagination
                    currentPage={category.pagination.currentPage}
                    pageCount={category.pagination.pageCount}
                    onChangePage={this.onChangePage}
                    forElement={category.key}
                  />}
                </KnowledgeSection>
              )
            })}
          </SwipeableViews>
        </div>
        }
      </Wrapper>
    )
  }
}

export default KnowledgePage