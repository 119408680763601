import React from "react"
import styled from "styled-components"
import ArrowLeft from "assets/images/svg/left-arrow.svg"
import ArrowRight from "assets/images/svg/right-arrow.svg"
import theme from "../assets/styles/theme"
import PropTypes from "prop-types"


const PaginationList = styled.nav`
  bottom: -1px;
  left: 0;
  min-height: 90px;
  width: 100%;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

const PaginationButton = styled.button`
  border: 0 none;
  background: none transparent;
  color: #ffffff;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg{
    width: 100%;
  }
  
  &[disabled]{
    color: ${theme.color.mainBlueColor};
    
    svg path{
      fill: ${theme.color.mainBlueColor} !important;
    }
  }
`


class Pagination extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentPage: null,
            pageCount: null,
            paginationItems: [],
        }
    }

    componentDidMount() {
        this.setPaginationItems()
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.currentPage !== props.currentPage) {
            return {
                currentPage: props.currentPage,
            }
        }
        return null
    }

    setPaginationItems() {
        if (this.props.currentPage && this.props.pageCount) {
            const { currentPage, pageCount, range } = this.props

            let paginationItems = []

            for (let i = 1; i <= pageCount; i++) {
                if (i >= currentPage - range && i <= currentPage + range) {
                    paginationItems.push(i)
                }
            }

            this.setState({
                paginationItems: paginationItems,
                currentPage: currentPage,
                pageCount: pageCount,
            })
        }
    }

    renderPaginationItem(item, child = item, disabled = item) {
        const { currentPage } = this.state

        return <PaginationButton
            key={"paginationItem-" + item}
            onClick={() => this.props.onChangePage(item, this.props.forElement)}
            disabled={currentPage === disabled && "disabled"}>
            {child}
        </PaginationButton>
    }

    render() {
        const { arrows } = this.props
        const { currentPage, pageCount, paginationItems } = this.state

        if ((currentPage && pageCount) && pageCount > 1) {
            return <PaginationList>
                {arrows && this.renderPaginationItem(currentPage - 1, <ArrowLeft/>, 1)}

                {paginationItems.map((item) => this.renderPaginationItem(item))}

                {arrows && this.renderPaginationItem(currentPage + 1, <ArrowRight/>, pageCount)}
            </PaginationList>
        }
        return null
    }
}

Pagination.defaultProps = {
    range: 2,
    arrows: true,
}

Pagination.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    range: PropTypes.number,
    arrows: PropTypes.bool,
    forElement: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

export default Pagination
