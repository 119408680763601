import React from "react"
import styled from "styled-components";
import axios from "axios";
import TopBar from "components/TopBar";
import SectionHeader from "components/SectionHeader";
import Loader from "components/Loader";
import ReactMarkdown from "react-markdown";
import theme from 'assets/styles/theme';
import ButtonLink from "components/ButtonLink";

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`;

const RewardsSection = styled.div`
    padding: 15px;
`;

const RewardsImage = styled.div`
    min-height: 35vh;
    background: url(${({background}) => (background ? background : '')})
      no-repeat;
    background-size: cover;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-position: center;
`;

const RewardsTitle = styled.p`
    position: relative;
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: bold;
`;

const RewardsPoints = styled.div`
    color: ${theme.color.mainBlueColor};
`;

const RewardsDescription = styled.div`
    font-size: 1.4rem;
    max-width: 100%;
    line-height: 1.5;
    margin-bottom: 3rem;
    
    blockquote {
        position: relative;
        font-size: 2rem;
        line-height: 1.5em;
        
        &:before {
            content: '\\201C';
            font-family: sans-serif;
            position: absolute;
            top: 2rem;
            left: -4.0rem;
            font-size: 12rem;
            color: rgba(255,255,255,0.5);
        }
        
        p {
            text-align: left;
        }
    }
    
    p {
        text-align: justify;
    }

    strong {
        font-weight: bold;
    }

    img {
        max-width: 100%;
    }
`;

class SingleRewardPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            error: false,
            singleReward: {},
        };
    }

    componentDidMount() {
        this.fetchSingleReward(this.props.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchSingleReward(this.props.id);
        }
    }

    fetchSingleReward = (id) => {
        if (!this.props.userProfile.accessToken) {
            return;
        }

        this.setState({loading: true});
        axios
            .get(`${process.env.API_URL}/rewards/${id}?access-token=${this.props.userProfile.accessToken}`)
            .then(singleRewards => {
                this.setState({
                    loading: false,
                    singleReward: singleRewards.data,
                });
            })
            .catch(error => {
                this.setState({loading: false, error: error});
            });
    };

    render() {
        const {loading, singleReward} = this.state;
        const {userProfile} = this.props;

        if (loading) {
            return (
                <Wrapper>
                    <RewardsSection>
                        <TopBar link="/app/rewards/" points={userProfile.saldo}/>
                        <SectionHeader header="Nagrody"/>
                        <Loader/>
                    </RewardsSection>
                </Wrapper>
            );
        } else if (singleReward.name) {
            return (
                <Wrapper>
                    <RewardsImage background={singleReward.imageUrl}/>
                    <RewardsSection>
                        <TopBar fixed={true} link="/app/rewards/" points={userProfile.saldo}/>
                        <RewardsTitle>
                            {singleReward.name}
                        </RewardsTitle>
                        <RewardsPoints>
                            {singleReward.points}pkt
                        </RewardsPoints>
                        <RewardsDescription>
                            <ReactMarkdown source={singleReward.description}/>
                        </RewardsDescription>
                        {
                            (userProfile.saldo - singleReward.points > 0) ?
                            <ButtonLink to={`/app/rewards-order/${singleReward.rewardId}/`} title="Zamów"/> :
                            <p>Masz za mało punktów, żeby zamówić tę nagrodę.</p>
                        }
                    </RewardsSection>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper>
                    <RewardsSection>
                        <TopBar link="/app/rewards/"/>
                        <SectionHeader header="Nagrody"/>
                        Coś poszło nie tak, przykro nam!
                    </RewardsSection>
                </Wrapper>
            );
        }
    }
}

export default SingleRewardPage;