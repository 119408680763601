import axios from "axios"
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "assets/styles/theme"
import Loader from "components/Loader"
import SectionHeader from "components/SectionHeader"
import SmallHeader from "components/SmallHeader"
import TopBar from "components/TopBar"
import FormAccountData from "../../Forms/FormAccountData"
import FormAddressData from "../../Forms/FormAddressData"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 150px;
    display: flex;
    flex-direction: column;
`

const FormWrapper = styled.div`
  position: relative;

  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

const AccountDetails = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;

    * {
      text-align: center;
    }
`

const DetailsLinks = styled(Link)`
    margin: 0 0 1em 0;
  text-align: center;
  color: #ffffff;
  border: 2px solid ${theme.color.mainBlueColor};
  padding: 1em;
  border-radius: 1.7em;
  font-weight: bold;
  
  .label{
    background-color: ${theme.color.mainBlueColor};
    border-radius: 1.5em;
    padding: 0.5em 1.2em;
    font-size: 0.9em;
    display: block;
    margin-top: 1rem;
    width: auto;
  }
`

const PromoCode = styled(DetailsLinks)`
  background: ${theme.color.mainBlueColor};
  
  .label{
    background-color: #000000;
  }
`

class AccountPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            address: {},
            showSnackbar: false,
            welcomePackButtonOpen: false,
            loading: true,
            barList: [],
            provinces: [],
            taxOfficeList: [],
            errors: {},
            userProfile: {},
        }
    }

    componentDidMount() {
        this.fetchWelcomePacks()
        this.fetchProvinces()
        this.setUserAddres()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userProfile.profileId !== this.props.userProfile.profileId) {
            this.fetchWelcomePacks()
            this.fetchProvinces()
            this.setUserAddres()
        }
    }

    fetchProvinces = ({ accessToken } = this.props.userProfile) => {
        if (accessToken) {
            this.setState({ loading: true })

            let params = {
                "access-token": accessToken,
            }

            axios
                .get(`${process.env.API_URL}/provinces`, { params })
                .then(provinces => {
                    this.setState({
                        loading: false,
                        provinces: provinces.data,
                    })
                })
                .catch(error => {
                    this.setState({ loading: false, error: error })
                })
        } else {
            return null
        }
    }

    fetchWelcomePacks = ({ accessToken, welcomepack } = this.props.userProfile) => {

        if (accessToken) {
            let didUserOrderWelcomepack = welcomepack.length > 0

            if (!didUserOrderWelcomepack) {
                this.setState({ loading: true })
                let params = {
                    "access-token": accessToken,
                }

                axios
                    .get(`${process.env.API_URL}/welcomepacks`, { params })
                    .then(welcomePack => {
                        let areWelcomepacksLeft = parseInt(welcomePack.data.welcomePackCount) > 0

                        if (areWelcomepacksLeft) {
                            this.setState({
                                loading: false,
                                welcomePackButtonOpen: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.setState({ loading: false, error: error })
                    })
            }
        } else {
            return null
        }
    }

    setUserAddres = () => {

        let address = this.props.userProfile.address ? this.props.userProfile.address : {}

        this.setState({
            userProfile: this.props.userProfile,
            address: {
                street: address.street ? address.street : "",
                houseNumber: address.houseNumber ? address.houseNumber : "",
                apartmentNumber: address.apartmentNumber ? address.apartmentNumber : "",
                zipCode: address.zipCode ? address.zipCode : "",
                city: address.city ? address.city : "",
                post: address.post ? address.post : "",
                commune: address.commune ? address.commune : "",
                poviat: address.poviat ? address.poviat : "",
                provinceId: address.provinceId ? address.provinceId : "",
            },
        })
    }

    render() {
        const { user, userProfile, setProfile } = this.props
        const { loading, welcomePackButtonOpen, provinces, address } = this.state
        return (
            <Wrapper>
                {loading && <Loader/>}

                <TopBar link="/app/more/" points={userProfile.saldo}/>
                <SectionHeader header="Moje konto"/>

                {userProfile.profileId &&
                <AccountDetails>
                    <PromoCode to="/app/more/recommend/">
                        Kod polecający: <span className={"label"}>{userProfile.verificationCode}</span>
                    </PromoCode>

                    <DetailsLinks to="/app/quizes/" state={{ activeCategoryIndex: 3 }}>
                        Liczba wypełnionych quizów: <span className={"label"}>{userProfile.questionnaires.length}</span>
                    </DetailsLinks>

                    <DetailsLinks to="/app/more/saldo/">
                        Historia punktów
                    </DetailsLinks>
                </AccountDetails>
                }
                {user.email &&
                <>
                    <SmallHeader subheader={"Konto zarejestrowane na"}/>
                    {user.email}
                </>
                }
                <FormWrapper>
                    <FormAccountData userProfile={this.state.userProfile} setProfile={setProfile}/>
                </FormWrapper>

                <FormWrapper>
                    <FormAddressData userProfile={userProfile} provinces={provinces} address={address}
                                     setProfile={setProfile}/>
                </FormWrapper>

            </Wrapper>
        )
    }
}

export default AccountPage