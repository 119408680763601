import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from "components/Button";
import theme from 'assets/styles/theme';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import LockIcon from "assets/images/svg/klodka_stock.svg";
import MatchIcon from "assets/images/svg/ogniwo_stock.svg";

const SingleQuestionWrapper = styled.div``;

const QuestionType = styled.div`
  margin-top: 2rem;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

const QuestionDescription = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const QuestionCounter = styled.div`
  text-align: center;
  border: 2px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
`;

const QuestionContent = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({image}) => (image ? `url(` + image + `)` : 'linear-gradient(90deg, rgba(28,30,44,0.95) 50%, rgba(33,30,33,0.95) 100%)')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  flex: 100%;
  font-weight: 500;
  max-width: 100%;
`;

const QuestionDIYContent = styled(QuestionContent)`
  min-height: 100px;
  min-width: 100px;
  background: ${({image}) => (image ? `url(` + image + `)` : 'white')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
  flex: none;
  color: ${theme.color.mainBlueColor};

  &.question-mark {
    font-size: 4rem;
  }
`;

const QuestionMatchContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const MatchRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  svg {
    width: 40px;
  }

  .answer-box {
    max-width: 50%;
    width: 100px;
    height: 100px;
    background: linear-gradient(90deg, rgba(28,30,44,0.95) 50%, rgba(33,30,33,0.95) 100%);
    border-radius: 15px;
    border: 2px dotted grey;
    margin: 1rem;

    &--small {
        width: 70px;
        height: 70px;
    }
  }
  
  &.correct {
    .answer-box {
        border: 3px solid green;
    }
  }

  &.wrong {
    .answer-box {
        border: 3px solid red;
    }
  }
`;

const QuestionAnswerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 1rem;
`;

const QuestionDIYAnswerWrapper = styled(QuestionAnswerWrapper)`
  justify-content: space-around;

  &.blurred {
      color: transparent;
      text-shadow: 0 0 5px white;
    }
`;

const QuestionMatchAnswerWrapper = styled(QuestionAnswerWrapper)`
  justify-content: space-around;

  .answer-box--small {
    max-width: 50%;
    width: 70px;
    height: 70px;
    background: linear-gradient(90deg, rgba(28,30,44,0.95) 50%, rgba(33,30,33,0.95) 100%);
    border-radius: 15px;
    border: 2px dotted grey;
    margin: 1rem;

    * {
      width: 100%;
      height: 100%;
    }
  }
`;

const QuestionSingleAnswer = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(28,30,44,0.95) 50%, rgba(33,30,33,0.95) 100%);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  width: 49%;
  margin-top: 0.8rem;
  font-weight: 500;

  &.selected {
    background: white;
    color: ${theme.color.mainBlueColor};
  }

  &.correct {
    background: green;
  }

  &.wrong {
    background: red;
  }

  &.should {
    border: 2px solid green;
  }
`;

const QuestionDIYSingleAnswer = styled(QuestionSingleAnswer)`
    min-height: 10px;
    padding: 0.8rem;
    min-width: 100px;
    font-size: 1.3rem;
    border-radius: 8px;
    width: auto;
`;

const QuestionMatchAnswer = styled(QuestionSingleAnswer)`
  width: 100%;
  height: 100%;
  margin: 0;
  background: ${({image}) => (image ? `url(` + image + `)` : 'darkgray')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`;

class Question extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeLeft: this.props.question.time,
            hasStarted: false,
            stopTimer: true,
            showCorrectAnswers: this.props.readOnly,
            chosenAnswers: [],
            questionAnswers: {
                questionId: this.props.question.questionId,
                answers: []
            }
        };

        this.possibleAnswers = this.props.question.answers.slice(0);

        this.matchAnswers = {
            answer_1_1: [],
            answer_2_1: [],
            answer_1_2: [],
            answer_2_2: []
        }
    }

    componentDidMount() {
        if (this.props.readOnly && this.props.question.template === 'match') {
            this.showMatchAnswers();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.question.questionId !== this.props.question.questionId) {
            this.setState({
                timeLeft: this.props.question.time,
                questionAnswers: {
                    questionId: this.props.question.questionId,
                    answers: []
                }
            });

            if (this.props.readOnly && this.props.question.template === 'match') {
                this.showMatchAnswers();
            } else {
                this.possibleAnswers = this.props.question.answers.slice(0);
            }
        }
    }

    showMatchAnswers = () => {
        let answers = this.props.question.answers;
        this.possibleAnswers = [];
        let usedIds = [];

        //First row of answers:
        let answer_1 = answers[0] ? [answers[0]] : [];

        let match_1 = this.props.question.answers.filter(item => {
            if (answers[0].answers.length > 0) {
                return item.answerId === answers[0].answers[0].matchAnswerId;
            }
            return false;
        });

        if (answers.length > 0 && match_1.length > 0) {
            usedIds.push(answers[0].answerId);
            usedIds.push(match_1[0].answerId);
        }

        //Second row of answers:
        let answer_2 = [];
        let match_2 = [];

        for (let i = 0; i < this.props.question.answers.length; i++) {
            let answer = this.props.question.answers[i];

            if (!(usedIds.indexOf(answer.answerId) > -1)) {
                answer_2 = [answer];
                match_2 = this.props.question.answers.filter(item => {
                    if (answer.answers.length > 0) {
                        return item.answerId === answer.answers[0].matchAnswerId;
                    }
                    return false;
                });
                break;
            }
        }

        this.matchAnswers = {
            answer_1_1: answer_1,
            answer_1_2: match_1,
            answer_2_1: answer_2,
            answer_2_2: match_2
        };
        this.forceUpdate();
    };

    handleStartQuestion = () => {
        this.props.onQuestionStart(true);
        this.setState({
            hasStarted: true,
            stopTimer: false,
        }, () => {
            this.timer = setInterval(() => {
                const timeLeft = this.state.timeLeft - 1;
                this.setState({
                    timeLeft: !this.state.stopTimer && timeLeft >= 0 ? timeLeft : this.state.timeLeft
                });

                if (timeLeft <= 0) {
                    this.handleShowAnswers();
                }
            }, 1000);
        });
    };

    handleShowAnswers = () => {
        clearInterval(this.timer);

        this.setState({
            stopTimer: true,
            showCorrectAnswers: true
        });
    };

    handleNextQuestion = () => {
        const self = this;

        if (this.props.readOnly) {
            self.props.nextQuestionHandler(this.state.questionAnswers);
        } else {
            this.setState({
                hasStarted: false,
                stopTimer: true,
                showCorrectAnswers: false,
            }, () => {
                self.props.nextQuestionHandler(this.state.questionAnswers);
            });
        }


    };

    handleSingleAnswerClick = (chosenAnswer) => {
        if (this.state.showCorrectAnswers) {
            return;
        }

        this.setState({
            chosenAnswers: [chosenAnswer],
            questionAnswers: {
                ...this.state.questionAnswers,
                answers: [{
                    ...this.answers,
                    answerId: chosenAnswer
                }]
            }
        })
    };

    handleDIYAnswerClick = (chosenAnswer) => {
        if (this.state.showCorrectAnswers) {
            return;
        }

        if (this.state.chosenAnswers.indexOf(chosenAnswer) > -1) {
            let chosenAnswers = this.state.chosenAnswers;
            chosenAnswers.splice(chosenAnswers.indexOf(chosenAnswer), 1);

            let answers = this.state.questionAnswers.answers.filter(function (el) {
                return el.answerId !== chosenAnswer;
            });

            this.setState({
                chosenAnswers: chosenAnswers,
                questionAnswers: {
                    ...this.state.questionAnswers,
                    answers: answers
                }
            })
        } else {
            let chosenAnswers = this.state.chosenAnswers;
            chosenAnswers.push(chosenAnswer);

            let answers = this.state.questionAnswers.answers;
            answers.push({
                answerId: chosenAnswer
            });

            this.setState({
                chosenAnswers: chosenAnswers,
                questionAnswers: {
                    ...this.state.questionAnswers,
                    answers: answers
                }
            })
        }
    };

    handleDragEnd = result => {
        if (!result.destination || result.destination.droppableId === result.source.droppableId) {
            return;
        }

        //To answer box - from possibilities
        let to_answer_box = ['answer_1_1', 'answer_1_2', 'answer_2_1', 'answer_2_2'].indexOf(result.destination.droppableId) > -1;
        let from_answer_box = ['answer_1_1', 'answer_1_2', 'answer_2_1', 'answer_2_2'].indexOf(result.source.droppableId) > -1;

        const answer = this.props.question.answers.filter((item) => {
            return item.answerId === result.draggableId;
        })[0];

        if (to_answer_box && from_answer_box) {
            //If object is already in
            if (this.matchAnswers[result.destination.droppableId].length > 0) {
                return;
            }

            // Move object between answers boxes
            this.matchAnswers = {
                ...this.matchAnswers,
                [result.destination.droppableId]: [answer],
                [result.source.droppableId]: []
            };
        } else if (to_answer_box && !from_answer_box) {
            //If object is already in
            if (this.matchAnswers[result.destination.droppableId].length > 0) {
                return;
            }

            // From possibilities to answer box
            this.possibleAnswers = this.possibleAnswers.filter((item) => {
                return item.answerId !== answer.answerId;
            });

            this.matchAnswers = {
                ...this.matchAnswers,
                [result.destination.droppableId]: [answer]
            };
        } else if (!to_answer_box && from_answer_box) {
            this.possibleAnswers.push(answer);
            this.forceUpdate();

            this.matchAnswers = {
                ...this.matchAnswers,
                [result.source.droppableId]: []
            };
        }

        let answers = [
            {
                answerId: this.matchAnswers.answer_1_1[0] ? this.matchAnswers.answer_1_1[0].answerId : null,
                match: this.matchAnswers.answer_1_2[0] ? [this.matchAnswers.answer_1_2[0].answerId] : []
            },
            {
                answerId: this.matchAnswers.answer_2_1[0] ? this.matchAnswers.answer_2_1[0].answerId : null,
                match: this.matchAnswers.answer_2_2[0] ? [this.matchAnswers.answer_2_2[0].answerId] : []
            }
        ];

        this.setState({
            questionAnswers: {
                ...this.state.questionAnswers,
                answers: answers
            }
        });
    };

    render() {
        const setClassName = (answer, index) => {
            if (this.state.chosenAnswers.includes(answer)) {
                if (this.state.showCorrectAnswers) {
                    if (this.props.question.answers[index].isCorrect) {
                        return 'correct';
                    } else {
                        return 'wrong';
                    }
                } else {
                    return 'selected';
                }
            } else if (this.state.showCorrectAnswers) {
                if (this.props.question.answers[index].isCorrect) {
                    return 'should';
                } else {
                    return '';
                }
            }
            return '';
        };

        const setMatchClassName = (rowNumber) => {
            if (this.state.showCorrectAnswers) {
                for (let i = 0; i < this.props.question.answers.length; i++) {
                    let answer = this.props.question.answers[i];

                    if (rowNumber === 0) {
                        if (this.matchAnswers.answer_1_1.length > 0 && this.matchAnswers.answer_1_2.length > 0) {
                            if (answer.answerId === this.matchAnswers.answer_1_1[0].answerId && answer.answers[0].matchAnswerId === this.matchAnswers.answer_1_2[0].answerId) {
                                return 'correct';
                            }
                        } else {
                            return 'wrong';
                        }
                    } else {
                        if (this.matchAnswers.answer_2_1.length > 0 && this.matchAnswers.answer_2_2.length > 0) {
                            if (answer.answerId === this.matchAnswers.answer_2_1[0].answerId && answer.answers[0].matchAnswerId === this.matchAnswers.answer_2_2[0].answerId) {
                                return 'correct';
                            }
                        } else {
                            return 'wrong';
                        }
                    }
                }

                return 'wrong';
            }
        };

        // 1 - ABC, 2 - DIY, 3 - Match
        if (this.props.question.template === 'abcd') {
            return (
                <SingleQuestionWrapper>
                    <QuestionType>ABCD</QuestionType>

                    {this.props.readOnly &&
                    <QuestionDescription>
                        Sprawdź prawidłowe odpowiedzi - czy na pewno wszystko pamiętasz?
                    </QuestionDescription>
                    }

                    {!this.props.readOnly &&
                    <div>
                        <QuestionDescription>
                            Zaznacz poprawną odpowiedź. Wciśnij start, kiedy będziesz chciał zacząć zabawę.
                        </QuestionDescription>
                        {this.state.timeLeft >= 0 &&
                        <QuestionCounter>
                            {this.state.timeLeft}
                        </QuestionCounter>
                        }
                    </div>
                    }

                    {!this.state.hasStarted && !this.state.showCorrectAnswers &&
                    <QuestionWrapper>
                        <QuestionContent>Wciśnij start, kiedy będziesz chciał zacząć zabawę.</QuestionContent>
                        <QuestionAnswerWrapper>
                            <QuestionSingleAnswer>A</QuestionSingleAnswer>
                            <QuestionSingleAnswer>B</QuestionSingleAnswer>
                            <QuestionSingleAnswer>C</QuestionSingleAnswer>
                            <QuestionSingleAnswer>D</QuestionSingleAnswer>
                        </QuestionAnswerWrapper>

                        <Button title="Start" onClick={this.handleStartQuestion}/>
                    </QuestionWrapper>
                    }

                    {(this.state.hasStarted || this.state.showCorrectAnswers) &&
                    <QuestionWrapper>
                        <QuestionContent
                            image={this.props.question.imageUrl}>{this.props.question.content}</QuestionContent>
                        <QuestionAnswerWrapper>
                            {this.props.question.answers.map((answer, index) => (
                                    <QuestionSingleAnswer
                                        key={answer.answerId}
                                        onClick={() => this.handleSingleAnswerClick(answer.answerId)}
                                        className={setClassName(answer.answerId, index)}
                                    >
                                        {answer.content}
                                    </QuestionSingleAnswer>
                                )
                            )}
                        </QuestionAnswerWrapper>

                        {!this.state.showCorrectAnswers &&
                        <Button title="Sprawdź odpowiedzi" onClick={this.handleShowAnswers}/>
                        }
                        {this.state.showCorrectAnswers && !this.props.isLast &&
                        <Button title="Następne pytanie" onClick={this.handleNextQuestion}/>
                        }
                        {this.state.showCorrectAnswers && this.props.isLast && !this.props.readOnly &&
                        <Button title="Zakończ Quiz" onClick={this.handleNextQuestion}/>
                        }

                    </QuestionWrapper>
                    }

                </SingleQuestionWrapper>
            );
        } else if (this.props.question.template === 'diy') {
            return (
                <SingleQuestionWrapper>
                    <QuestionType>DIY</QuestionType>
                    {this.props.readOnly &&
                    <QuestionDescription>
                        Sprawdź prawidłowe odpowiedzi - czy na pewno wszystko pamiętasz?
                    </QuestionDescription>
                    }

                    {!this.props.readOnly &&
                    <div>
                        <QuestionDescription>
                            Zaznacz tylko te składniki, które są potrzebne do przygotowania koktajlu. Wciśnij start,
                            kiedy będziesz chciał zacząć zabawę.
                        </QuestionDescription>

                        {this.state.timeLeft >= 0 &&
                        <QuestionCounter>
                            {this.state.timeLeft}
                        </QuestionCounter>
                        }
                    </div>
                    }

                    {!this.state.hasStarted && !this.state.showCorrectAnswers &&
                    <QuestionWrapper>
                        <QuestionDIYContent className="question-mark">?</QuestionDIYContent>
                        <QuestionDIYAnswerWrapper className="blurred">
                            <QuestionDIYSingleAnswer>A</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>B</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>C</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>E</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>F</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>G</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>H</QuestionDIYSingleAnswer>
                            <QuestionDIYSingleAnswer>I</QuestionDIYSingleAnswer>
                        </QuestionDIYAnswerWrapper>

                        <Button title="Start" onClick={this.handleStartQuestion}/>
                    </QuestionWrapper>
                    }

                    {(this.state.hasStarted || this.state.showCorrectAnswers) &&
                    <QuestionWrapper>
                        <QuestionDIYContent
                            image={this.props.question.imageUrl}>{this.props.question.content}</QuestionDIYContent>
                        <QuestionDIYAnswerWrapper>
                            {this.props.question.answers.map((answer, index) => (
                                    <QuestionDIYSingleAnswer
                                        key={answer.answerId}
                                        onClick={() => this.handleDIYAnswerClick(answer.answerId)}
                                        className={setClassName(answer.answerId, index)}
                                    >
                                        {answer.content}
                                    </QuestionDIYSingleAnswer>
                                )
                            )}
                        </QuestionDIYAnswerWrapper>

                        {!this.state.showCorrectAnswers &&
                        <Button title="Sprawdź odpowiedzi" onClick={this.handleShowAnswers}/>
                        }
                        {this.state.showCorrectAnswers && !this.props.isLast &&
                        <Button title="Następne pytanie" onClick={this.handleNextQuestion}/>
                        }
                        {this.state.showCorrectAnswers && this.props.isLast && !this.props.readOnly &&
                        <Button title="Zakończ Quiz" onClick={this.handleNextQuestion}/>
                        }

                    </QuestionWrapper>
                    }
                </SingleQuestionWrapper>
            );
        } else if (this.props.question.template === 'match') {
            return (
                <SingleQuestionWrapper>
                    <QuestionType>Dopasowanie</QuestionType>
                    {this.props.readOnly &&
                    <QuestionDescription>
                        Sprawdź prawidłowe odpowiedzi - czy na pewno wszystko pamiętasz?
                    </QuestionDescription>
                    }

                    {!this.props.readOnly &&
                    <div>
                        {!this.state.hasStarted &&
                        <QuestionDescription>
                            Dopasuj do siebie elementy. Przeciągnij je do boksów, żeby połączyć je w pary.
                        </QuestionDescription>
                        }

                        {this.state.hasStarted &&
                        <QuestionDescription>
                            {this.props.question.content}
                        </QuestionDescription>
                        }

                        {this.state.timeLeft >= 0 &&
                        <QuestionCounter>
                            {this.state.timeLeft}
                        </QuestionCounter>
                        }
                    </div>
                    }

                    {!this.state.hasStarted && !this.state.showCorrectAnswers &&
                    <QuestionWrapper>
                        <QuestionMatchContent>
                            <MatchRow>
                                <div className='answer-box'/>
                                <MatchIcon/>
                                <div className='answer-box'/>
                            </MatchRow>
                            <MatchRow>
                                <div className='answer-box'/>
                                <MatchIcon/>
                                <div className='answer-box'/>
                            </MatchRow>
                        </QuestionMatchContent>
                        <QuestionMatchAnswerWrapper>
                            <div className="answer-box--small">
                                <QuestionMatchAnswer>
                                    <LockIcon/>
                                </QuestionMatchAnswer>
                            </div>
                            <div className="answer-box--small">
                                <QuestionMatchAnswer>
                                    <LockIcon/>
                                </QuestionMatchAnswer>
                            </div>
                            <div className="answer-box--small">
                                <QuestionMatchAnswer>
                                    <LockIcon/>
                                </QuestionMatchAnswer>
                            </div>
                            <div className="answer-box--small">
                                <QuestionMatchAnswer>
                                    <LockIcon/>
                                </QuestionMatchAnswer>
                            </div>
                        </QuestionMatchAnswerWrapper>

                        <Button title="Start" onClick={this.handleStartQuestion}/>
                    </QuestionWrapper>
                    }

                    {(this.state.hasStarted || this.state.showCorrectAnswers) &&
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                        <QuestionWrapper>
                            <QuestionMatchContent>
                                <MatchRow className={setMatchClassName(0)}>
                                    <Droppable droppableId="answer_1_1">
                                        {(provided) => (
                                            <div className="answer-box"
                                                 ref={provided.innerRef}
                                                 {...provided.droppableProps}
                                            >
                                                {this.matchAnswers.answer_1_1.map((answer, index) => (
                                                    <Draggable
                                                        draggableId={answer.answerId}
                                                        index={index}
                                                        key={answer.answerId}
                                                        isDragDisabled={this.state.showCorrectAnswers}
                                                    >
                                                        {(provided) => (
                                                            <QuestionMatchAnswer
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                image={answer.imageUrl}
                                                            >
                                                                {answer.content}
                                                            </QuestionMatchAnswer>
                                                        )}
                                                    </Draggable>)
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    <MatchIcon/>
                                    <Droppable droppableId="answer_1_2">
                                        {(provided) => (
                                            <div className="answer-box"
                                                 ref={provided.innerRef}
                                                 {...provided.droppableProps}
                                            >
                                                {this.matchAnswers.answer_1_2.map((answer, index) => (
                                                    <Draggable
                                                        draggableId={answer.answerId}
                                                        index={index}
                                                        key={answer.answerId}
                                                        isDragDisabled={this.state.showCorrectAnswers}
                                                    >
                                                        {(provided) => (
                                                            <QuestionMatchAnswer
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                image={answer.imageUrl}
                                                            >
                                                                {answer.content}
                                                            </QuestionMatchAnswer>
                                                        )}
                                                    </Draggable>)
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </MatchRow>
                                <MatchRow className={setMatchClassName(1)}>
                                    <Droppable droppableId="answer_2_1">
                                        {(provided) => (
                                            <div className="answer-box"
                                                 ref={provided.innerRef}
                                                 {...provided.droppableProps}
                                            >
                                                {this.matchAnswers.answer_2_1.map((answer, index) => (
                                                    <Draggable
                                                        draggableId={answer.answerId}
                                                        index={index}
                                                        key={answer.answerId}
                                                        isDragDisabled={this.state.showCorrectAnswers}
                                                    >
                                                        {(provided) => (
                                                            <QuestionMatchAnswer
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                image={answer.imageUrl}
                                                            >
                                                                {answer.content}
                                                            </QuestionMatchAnswer>
                                                        )}
                                                    </Draggable>)
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    <MatchIcon/>
                                    <Droppable droppableId="answer_2_2">
                                        {(provided) => (
                                            <div className="answer-box"
                                                 ref={provided.innerRef}
                                                 {...provided.droppableProps}
                                            >
                                                {this.matchAnswers.answer_2_2.map((answer, index) => (
                                                    <Draggable
                                                        draggableId={answer.answerId}
                                                        index={index}
                                                        key={answer.answerId}
                                                        isDragDisabled={this.state.showCorrectAnswers}
                                                    >
                                                        {(provided) => (
                                                            <QuestionMatchAnswer
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                image={answer.imageUrl}
                                                            >
                                                                {answer.content}
                                                            </QuestionMatchAnswer>
                                                        )}
                                                    </Draggable>)
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </MatchRow>
                            </QuestionMatchContent>
                            <QuestionMatchAnswerWrapper>

                                {this.props.question.answers.map((answer, index) => (
                                        <div className="answer-box--small"
                                             key={answer.answerId}>
                                            <Droppable
                                                droppableId={'answer_' + index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}>

                                                        {this.possibleAnswers.map((possible_answer, smallIndex) => {
                                                            if (answer.answerId === possible_answer.answerId) {
                                                                return (
                                                                    <Draggable
                                                                        draggableId={answer.answerId}
                                                                        index={smallIndex}
                                                                        key={answer.answerId}
                                                                        isDragDisabled={this.state.showCorrectAnswers}
                                                                    >
                                                                        {(provided) => (
                                                                            <QuestionMatchAnswer
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                                image={answer.imageUrl}
                                                                            >
                                                                                {answer.content}
                                                                            </QuestionMatchAnswer>
                                                                        )}
                                                                    </Draggable>)
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    )
                                )}
                            </QuestionMatchAnswerWrapper>

                            {!this.state.showCorrectAnswers &&
                            <Button title="Sprawdź odpowiedzi" onClick={this.handleShowAnswers}/>
                            }
                            {this.state.showCorrectAnswers && !this.props.isLast &&
                            <Button title="Następne pytanie" onClick={this.handleNextQuestion}/>
                            }
                            {this.state.showCorrectAnswers && this.props.isLast && !this.props.readOnly &&
                            <Button title="Zakończ Quiz" onClick={this.handleNextQuestion}/>
                            }
                        </QuestionWrapper>
                    </DragDropContext>

                    }
                </SingleQuestionWrapper>
            );
        } else {
            return (
                <div>Coś poszło nie tak... Skontaktuj się z pomocą techniczną.</div>
            )
        }
    }
}

Question.defaultProps = {
    question: {}
};

Question.propTypes = {
    question: PropTypes.object,
};

export default Question;
