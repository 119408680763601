import * as Yup from "yup"
import axios from "axios"
import React from "react"
import {Form, Formik} from "formik"
import {ErrorMessage, TextInput} from "./FormikInputs"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "../Button"
import Loader from "../Loader"
import SmallHeader from "../SmallHeader"
import AutocompleteInput from "./AutocompleteInput"
import SwitchInput from "./SwitchInput"
import {setNotificationPermissions, getNotificationPermissions} from "services/pushManager"

const ValidationMessages = {
    required: "Pole jest wymagane",
}

const sendUserData = (data, accessToken, profileId) => {
    let params = {
        "access-token": accessToken,
        "expand": "saldoHistory,questionnaires,address,contestToUser.contest,welcomepack",
    }

    return axios.patch(`${process.env.API_URL}/profiles/${profileId}`, data, {params})
}

const handleBarAutocompleteChange = (value) => {
    let params = {
        "filter[name][like]": value,
    }

    return axios.get(`${process.env.API_URL}/bars`, { params })
        .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            },
        )
}

const FormAccountData = (props) => {
    const {phone, firstName, lastName, birthDate, restaurant, city, instagram, accessToken, profileId} = props.userProfile

    const handleSwitchChange = (value) => {
        let permissions = value ? 'granted' : 'denied';
        setNotificationPermissions(permissions, accessToken);
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                pushNotifications: getNotificationPermissions() === 'granted',
                firstName: firstName ? firstName : "",
                lastName: lastName ? lastName : "",
                birthDate: birthDate ? birthDate : "",
                restaurant: restaurant ? restaurant : "",
                city: city ? city : "",
                phone: phone ? phone : "",
                instagram: instagram ? instagram : "",
            }}
            validationSchema={Yup.object({
                firstName: Yup.string()
                    .required(ValidationMessages.required),
                lastName: Yup.string()
                    .required(ValidationMessages.required),
                restaurant: Yup.string()
                    .required(ValidationMessages.required),
                city: Yup.string()
                    .required(ValidationMessages.required),
                phone: Yup.string(),
                instagram: Yup.string(),
                birthDate: Yup.string(),
            })}
            onSubmit={async (values, actions) => {
                await sendUserData(values, accessToken, profileId)
                    .then(response => {
                        if (response.status === 200) {
                            actions.setStatus("success")
                            
                            setTimeout(() => {
                                props.setProfile(response.data)
                            }, 2000)
                        }
                    })
                    .catch(error => {
                        actions.setStatus("error")
                        if (error.response.data.message) {
                            actions.setFieldError("general", error.response.data.name)
                        } else if (error.response.data) {
                            error.response.data.forEach((item) => {
                                actions.setFieldError(item.field, item.message)
                            })
                        }
                    })
                    .finally(() => {
                        setTimeout(() => actions.setStatus(""), 2000)
                    })
            }}
        >
            {formikProps => (
                <Form>
                    <Snackbar
                        className={typeof (formikProps.status) !== "object" ? formikProps.status : ""}
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        open={formikProps.status !== ""}
                        autoHideDuration={2000}
                        message={<>
                            {formikProps.status === 'success' &&
                            <span>Pomyślnie zaktualizowano</span>
                            }

                            {formikProps.status === 'error' &&
                            <span>Wprowadzone dane są nieprawidłowe</span>
                            }
                        </>}
                    />
                    <SmallHeader subheader={"Ustawienia"}/>
                    <SwitchInput onChange={handleSwitchChange}
                                 initialValue={formikProps.initialValues.pushNotifications} label="Powiadomienia PUSH"/>

                    <SmallHeader subheader={"Dane osobowe"}/>
                    <TextInput name="firstName" type="text" placeholder="Imię *"/>
                    <TextInput name="lastName" type="text" placeholder="Nazwisko *"/>
                    <TextInput name="birthDate" type="date" placeholder="Data urodzenia"/>

                    <AutocompleteInput
                        name="restaurant"
                        value={formikProps.initialValues.restaurant}
                        placeholder="Nazwa baru *"
                        autosuggestId="bar_autocomplete"
                        onChange={(value) => {
                            formikProps.setFieldValue("restaurant", value)
                            handleBarAutocompleteChange(value)
                                .then(data => {
                                        formikProps.setStatus({ "barList": data })
                                    },
                                )
                        }}
                        options={formikProps.status ? formikProps.status.barList : []}
                    />
                    <TextInput name="city" type="text" placeholder="Miasto *"/>
                    <TextInput name="phone" type="text" placeholder="Numer telefonu" mask={"+48 999 999 999"}/>

                    <SmallHeader subheader={"Nazwa konta @instagram"}/>
                    <TextInput name="instagram" type="text" placeholder="Nazwa konta @instagram"/>
                    {formikProps.errors.general &&
                    <ErrorMessage>
                        {formikProps.errors.general}
                    </ErrorMessage>
                    }
                    {formikProps.isSubmitting ? <Loader/> : null}
                    <Button type="submit" title={"Zapisz dane osobowe"} disabled={formikProps.isSubmitting}/>

                </Form>
            )}
        </Formik>
    )
}
export default FormAccountData