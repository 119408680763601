import React from "react"
import {graphql, Link, StaticQuery} from "gatsby"
import styled from "styled-components"
import TopBar from "components/TopBar"
import ValuePropositionSlider from "../ValuePropositionSlider"
import Loader from "components/Loader";

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`;

const StyledLink = styled(Link)`
    width: 100%;
    background: url(${({background}) => (background ? background : "")})
      no-repeat;
    background-size: cover;
    background-position: ${({backgroundposition}) => (backgroundposition ? backgroundposition : "center")};
    min-height: ${({high}) => (high ? "220px" : "110px")};
    margin-bottom: 2rem;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    font-size: 2.3rem;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    }
    
    &:hover, &:focus {
        text-decoration: none;    
    }
    
    p {
        position: relative;
        margin: 0;
        width: 60%;
    }
`

class DashboardPage extends React.Component {
    render() {
        if (typeof window !== "undefined") {
            return (
                <StaticQuery
                    query={graphql`
                      query {
                        contests: file(relativePath: { eq: "contests.jpg" }) {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        quizes: file(relativePath: { eq: "quizes.jpg" }) {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        news: file(relativePath: { eq: "news.jpg" }) {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        knowledge: file(relativePath: { eq: "knowledge.jpg" }) {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                `}
                    render={data => (
                        <Wrapper>
                            {!this.props.userProfile && <Loader/>}

                            {this.props.userProfile &&
                            <TopBar points={this.props.userProfile.saldo}/>
                            }

                            <StyledLink
                                high="true"
                                background={data.contests.childImageSharp.fluid.src}
                                to="/app/contests/">
                                <p>Konkursy</p>
                            </StyledLink>

                            <StyledLink
                                background={data.quizes.childImageSharp.fluid.src}
                                backgroundposition='bottom'
                                to="/app/quizes/">
                                <p>Quizy</p>
                            </StyledLink>

                            <StyledLink
                                background={data.news.childImageSharp.fluid.src}
                                to="/app/news/">
                                <p>Aktualności</p>
                            </StyledLink>

                            <StyledLink
                                background={data.knowledge.childImageSharp.fluid.src}
                                backgroundposition='bottom'
                                to="/app/knowledge/">
                                <p>Wiedza barmańska</p>
                            </StyledLink>

                            {this.props.userProfile && <>
                                <ValuePropositionSlider userProfile={this.props.userProfile}/>
                            </>}
                        </Wrapper>
                    )}
                />
            )
        }
        return null
    }
}

export default DashboardPage