import React from "react"
import styled from "styled-components";
import axios from "axios";
import TopBar from "components/TopBar";
import SectionHeader from "components/SectionHeader";
import Loader from "components/Loader";
import ReactMarkdown from "react-markdown";
import theme from 'assets/styles/theme';

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`;

const NewsSection = styled.div`
    padding: 15px;
`;

const NewsImage = styled.div`
    min-height: 35vh;
    background: url(${({background}) => (background ? background : '')})
      no-repeat;
    background-size: cover;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-position: center;
`;

const NewsTitle = styled.p`
    position: relative;
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: bold;
`;

const NewsDescription = styled.div`
    font-size: 1.4rem;
    max-width: 100%;
    line-height: 1.5;
    
    blockquote {
        position: relative;
        font-size: 2rem;
        line-height: 1.5em;
        
        &:before {
            content: '\\201C';
            font-family: sans-serif;
            position: absolute;
            top: 2rem;
            left: -4.0rem;
            font-size: 12rem;
            color: rgba(255,255,255,0.5);
        }

        p {
            text-align: left;
        }
    }

    iframe{
        width: 100%;
        height: 50vw;
    }

    p {
        text-align: justify;
    }

    strong {
        font-weight: bold;
    }

    img {
        max-width: 100%;
    }
`;

const NewsExternalURL = styled.button`
    display: block;
    position: relative;
    width: 250px;
    padding: 0;
    text-align: center;
    line-height: 50px;
    color: white;
    border-radius: 30px;
    background: ${theme.color.mainBlueColor};
    margin: 0 auto;
    margin-top: 5rem;
`;

class SingleNewsPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loading: true,
            error: false,
            singleNews: {},
        };
    }

    componentDidMount() {
        this.fetchSingleNews(this.props.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchSingleNews(this.props.id);
        }
    }

    fetchSingleNews = (id) => {
        if (!this.props.userProfile.accessToken) {
            return;
        }

        this.setState({loading: true});
        axios
            .get(`${process.env.API_URL}/news/${id}?access-token=${this.props.userProfile.accessToken}`)
            .then(singleNews => {
                this.setState({
                    loading: false,
                    singleNews: singleNews.data,
                });
            })
            .catch(error => {
                this.setState({loading: false, error: error});
            });
    };

    render() {
        if (this.state.loading) {
            return (
                <Wrapper>
                    <NewsSection>
                        <TopBar link="/app/news/"/>
                        <SectionHeader header="Aktualności"/>
                        <Loader/>
                    </NewsSection>
                </Wrapper>
            );
        } else if (this.state.singleNews.title) {
            return (
                <Wrapper>
                    <NewsImage background={this.state.singleNews.imageUrl}/>
                    <NewsSection>
                        <TopBar link="/app/news/" fixed="true"/>
                        <NewsTitle>
                            {this.state.singleNews.title}
                        </NewsTitle>
                        <NewsDescription>
                            <ReactMarkdown source={this.state.singleNews.content} escapeHtml={false}/>
                        </NewsDescription>

                        {this.state.singleNews.externalUrl &&
                        <NewsExternalURL as='a' href={this.state.singleNews.externalUrl}>
                            Czytaj więcej
                        </NewsExternalURL>
                        }
                    </NewsSection>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper>
                    <NewsSection>
                        <TopBar link="/app/news/"/>
                        <SectionHeader header="Aktualności"/>
                        Coś poszło nie tak, przykro nam!
                    </NewsSection>
                </Wrapper>
            );
        }
    }
}

export default SingleNewsPage;