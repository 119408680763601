import React from "react"
import axios from "axios"
import styled from "styled-components"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SwipeableViews from "react-swipeable-views"
import { Link } from "gatsby"
import InstaLogo from "assets/images/svg/logo_insta.svg"
import theme from "assets/styles/theme"
import Loader from "components/Loader"
import SectionHeader from "components/SectionHeader"
import TopBar from "components/TopBar"
import TimeLeft from "components/TimeLeft"
import {checkNotificationPermission} from "services/pushManager"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 90px 15px;
    display: flex;
    flex-direction: column;
`

const ContestsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const ContestsWrapper = styled(Link)`
    width: 100%;
    background: url(${({image}) => (image ? image : '')})
      no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 220px;
    margin-bottom: 2rem;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    grid-gap: 0.5rem;
    align-items: start;
    font-size: 2.3rem;
    position: relative;
    order: 1;
    
    .instagram-icon {
      grid-column: -1 / -2;
      grid-row: 1 / 2;
      
      svg {
        width: 25px;
        height: 25px;
      }
    }
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    }

    &:hover, &:focus {
        text-decoration: none;
    }
`

const ContestsSmallWrapper = styled(ContestsWrapper)`
  order: 2;
  
  .instagram-icon {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      z-index: 1;
      
    }
`

const ContestsTags = styled.div`
    font-size: 1.3rem;
    display: flex;
    width: 100%;
    padding: 0.5rem;
    flex-wrap: wrap;
    z-index: 50;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    overflow: hidden;
`

const TagName = styled.div`
  padding: 0.7rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 15px;
  background-color: ${theme.color.mainBlueColor};
  box-shadow: 0 0 0.5rem 0 rgba(0,48,255,0.8);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 130px;
  width: auto;
`

const ContestsTitle = styled.p`
    position: relative;
    margin: 1rem 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    grid-column: 1 / -1;
    grid-row: -1 / -2;
`

const ContestsSmallTitle = styled(ContestsTitle)`
    font-size: 2rem;
`

const StyledTabs = styled(Tabs)`
    margin: 2rem 0;

    .MuiTabs-indicator {
        background-color: ${theme.color.mainBlueColor};
    }
`

const StyledTab = styled(Tab)`
    font-size: 1.3rem !important;
    min-width: 33% !important;
    text-transform: none !important;
    opacity: 1 !important;
    font-family: inherit !important;
    border-bottom: 2px solid !important;

    &.Mui-selected {
        color: ${theme.color.mainBlueColor} !important;
        font-weight: bold !important;
    }
`

const TimeLeftCounter = styled(TimeLeft)`
  font-size: 1.3rem;
  color: #ffffff;
  margin: 0;
  z-index: 0;
  grid-row: 2 / -2;
  grid-column: 1 / -1;
`

class ContestsPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeCategoryIndex: 0,
            loading: true,
            error: false,
            contestItems: [],
        }
    }

    componentDidMount() {
        this.fetchContests()

        setTimeout(() => {
            checkNotificationPermission(this.props.userProfile.accessToken)
        }, 3000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchContests()
        }
    }

    fetchContests = () => {
        if (!this.props.userProfile.accessToken) {
            return
        }

        this.setState({ loading: true })
        axios
            .get(`${process.env.API_URL}/contests?access-token=${this.props.userProfile.accessToken}&sort=-created_at`)
            .then(contestItems => {
                this.setState({
                    loading: false,
                    contestItems: contestItems.data,
                })
            })
            .catch(error => {
                this.setState({ loading: false, error: error })
            })
    }

    handleTabChange = (event, value) => {
        this.setState({
            activeCategoryIndex: value,
        })
    }

    handleTabChangeIndex = activeCategoryIndex => {
        this.setState({
            activeCategoryIndex,
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Wrapper>
                    <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader header="Konkursy"/>
                    <Loader/>
                </Wrapper>
            )
        } else if (this.state.contestItems.length > 0) {
            const activeCategoryIndex = this.state.activeCategoryIndex
            const contestCategory_1 = this.state.contestItems.filter(item => {
                let today = new Date()
                let dateFrom = new Date(item.dateFrom)
                let dateTo = item.dateTo ? new Date(item.dateTo) : new Date(8640000000000000)

                let isAfterFrom = dateFrom < today
                let isBeforeTo = today < dateTo

                return isAfterFrom && isBeforeTo
            })

            let userContests = this.props.userProfile.contestToUser
            const contestCategory_2 = userContests.filter(item => {
                return item.relation === 1
            })

            const contestCategory_3 = this.state.contestItems.filter(item => {
                let today = new Date()
                let dateFrom = new Date(item.dateFrom)
                let dateTo = item.dateTo ? new Date(item.dateTo) : new Date(8640000000000000)

                let isAfterFrom = dateFrom < today
                let isAfterTo = dateTo < today

                return isAfterFrom && isAfterTo
            })

            return (
                <Wrapper>
                    <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader header="Konkursy"/>

                    <div>
                        <StyledTabs value={activeCategoryIndex} variant="scrollable" onChange={this.handleTabChange}>
                            <StyledTab label="Aktywne"/>
                            <StyledTab label="Moje konkursy"/>
                            <StyledTab label="Rozstrzygnięte"/>
                        </StyledTabs>
                        <SwipeableViews index={activeCategoryIndex} onChangeIndex={this.handleTabChangeIndex}>
                            <ContestsSection>
                                {contestCategory_1.map(item => {
                                    // Instagram contest = 1, normal contest = 2
                                    if (item.type === 1) {
                                        return (
                                            <ContestsWrapper to={`/app/contests-contest/${item.contestId}/`}
                                                             key={item.contestId}
                                                             image={item.imageUrl}>

                                                <div className="instagram-icon">
                                                    <InstaLogo/>
                                                </div>
                                                {item.flags && item.flags.showTimer &&
                                                <TimeLeftCounter dateTo={item.dateTo}/>
                                                }
                                                <ContestsTags>
                                                    {item.tag && <TagName>{item.tag}</TagName>}
                                                    {item.hashTag && <TagName>#{item.hashTag}</TagName>}
                                                </ContestsTags>

                                                <ContestsTitle>{item.name}</ContestsTitle>
                                            </ContestsWrapper>
                                        )
                                    } else {
                                        return (
                                            <ContestsSmallWrapper to={`/app/contests-contest/${item.contestId}/`}
                                                                  key={item.contestId}
                                                                  image={item.imageUrl}>
                                                {item.flags && <>
                                                    {item.flags.showTimer &&
                                                    <TimeLeftCounter dateTo={item.dateTo}/>
                                                    }
                                                </>}
                                                <ContestsTags>
                                                    {item.tag && <TagName>{item.tag}</TagName>}
                                                    {item.hashTag && <TagName>#{item.hashTag}</TagName>}
                                                </ContestsTags>

                                                <ContestsSmallTitle>{item.name}</ContestsSmallTitle>
                                            </ContestsSmallWrapper>
                                        )
                                    }
                                })}

                                {contestCategory_1.length === 0 && <>
                                    Zakładka "Aktywne" jest obecnie pusta.
                                </>}
                            </ContestsSection>
                            <ContestsSection>
                                {contestCategory_2.map(item => {
                                        // Instagram contest = 1, normal contest = 2
                                        return (
                                            <ContestsSmallWrapper to={`/app/contests-contest/${item.contestId}/`}
                                                                  key={item.contestId}
                                                                  image={item.contest.imageUrl}>

                                                {item.contest.type === 1 &&
                                                <div className="instagram-icon">
                                                    <InstaLogo/>
                                                </div>
                                                }
                                                {item.flags && <>
                                                {item.flags.showTimer &&
                                                        <TimeLeftCounter dateTo={item.dateTo}/>
                                                }
                                                </>}
                                                <ContestsTags>
                                                    {item.tag && <TagName>{item.tag}</TagName>}
                                                    {item.hashTag && <TagName>#{item.hashTag}</TagName>}
                                                </ContestsTags>

                                                <ContestsTitle>{item.contest.name}</ContestsTitle>
                                            </ContestsSmallWrapper>
                                        )
                                    },
                                )}

                                {contestCategory_2.length === 0 && <>
                                    Zakładka "moje konkursy" jest obecnie pusta. Aby dodać do niej konkurs przejdź do
                                    sekcji "aktywne", otwórz dowolny konkurs i kliknij w ikonę gwiazdki.
                                </>}
                            </ContestsSection>
                            <ContestsSection>
                                {contestCategory_3.map(item => {
                                        // Instagram contest = 1, normal contest = 2
                                        return (
                                            <ContestsSmallWrapper to={`/app/contests-contest/${item.contestId}/`}
                                                                  key={item.contestId}
                                                                  image={item.imageUrl}>

                                                {item.type === 1 &&
                                                <div className="instagram-icon">
                                                    <InstaLogo/>
                                                </div>
                                                }

                                                <ContestsTags>
                                                    {item.tag && <TagName>{item.tag}</TagName>}
                                                    {item.hashTag && <TagName>#{item.hashTag}</TagName>}
                                                </ContestsTags>

                                                <ContestsTitle>{item.name}</ContestsTitle>
                                            </ContestsSmallWrapper>
                                        )
                                    },
                                )}
                            </ContestsSection>
                        </SwipeableViews>
                    </div>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper>
                    <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader header="Konkursy"/>
                    Nie mamy żadnych konkursów do pokazania. Kiedyś na pewno się pojawią!
                </Wrapper>
            )
        }
    }
}

export default ContestsPage

