import React from "react"
import PropTypes from "prop-types"

const PrivateRoute = ({ component: Component, location, user, ...rest }) => {
    if (!user) {
        return null;
    }

    return <Component user={user} {...rest} />
};

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired
};

export default PrivateRoute
