import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from "assets/styles/theme";

const TextInputWrapper = styled.div`
    text-align: center;
    margin-bottom: 1rem;
    padding: ${({padding}) => (padding ? padding : '0 24px')};
    
    span {
        margin-top: 1rem;
    }
`;

const StyledInput = styled.input`
    position: relative;
    width: 100%;
    min-height: 45px;
    background: none  transparent !important;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: ${theme.radius.input};
    border: 1px solid ${theme.color.inputBorderColor};
    padding: ${theme.padding.input};

    &:focus {
      outline: none;
    }
`;

const SimpleStyledInput = styled(StyledInput)`
    text-align: left;
`;

const StyledError = styled.div`
    text-align: center;
    margin-bottom: 1rem;
`;


class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            isValid: true,
            error: "",
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value === "" && this.props.value !== "") {
            this.setState({
                isValid: true,
                value: this.props.value,
                error: ""
            }, () => {
                this.validateInput(this.props.name, this.props.value)
            });
        }
    }

    handleChange = event => {
        if (event.target.files.length <= 0) {
            this.props.onChange('');
            return;
        }

        if (event.target.files[0].type !== 'image/jpg' &&
            event.target.files[0].type !== 'image/jpeg' &&
            event.target.files[0].type !== 'image/png') {

            this.setState({
                value: '',
                isValid: false,
                error: 'Podany plik nie jest zdjęciem.'
            }, () => {
                this.props.onChange(this.state.value);
            });
        } else {
            let self = this;
            let FR = new FileReader();

            FR.addEventListener("load", function (e) {
                self.setState({
                    value: e.target.result,
                    isValid: true,
                    error: ""
                }, () => {
                    self.props.onChange(e.target.result);
                });
            });

            FR.readAsDataURL(event.target.files[0]);
        }
    };

    render() {
        return (
            <TextInputWrapper padding={this.props.meta.padding}>
                <SimpleStyledInput
                    type='file'
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    value={this.props.value}
                    accept={this.props.accept}
                    multiple={this.props.multiple}
                />

                {!this.state.isValid &&
                <StyledError>
                    {this.state.error}
                </StyledError>
                }
            </TextInputWrapper>
        )
    }
}

Input.defaultProps = {
    name: `Input`,
    placeholder: `Placeholder`,
};

Input.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default Input;
