import React from 'react';
import styled from "styled-components";
import theme from "assets/styles/theme";

const TextAreaInputWrapper = styled.div`
    padding: ${({padding}) => (padding ? padding : '0 24px')};
    text-align: center;
    margin-bottom: 1rem;
    
    span {
        margin-top: 1rem;
    }
`;

const StyledInput = styled.textarea`
    position: relative;
    width: 100%;
    color: white;
    border-image: initial;
    background: none;
    border-radius: ${theme.radius.input};
    border: 1px solid ${theme.color.inputBorderColor};
    padding: ${theme.padding.input};

    &:focus {
      outline: none;
    }
`;

const SimpleStyledInput = styled(StyledInput)`
    text-align: left;

    &:focus {
      outline: none;
    }
`;

const TextAreaInput = ({handler, touched, hasError, meta}) => {
    return (
        <TextAreaInputWrapper padding={meta.padding}>
            {meta.style === 'bordered' &&
            <div>
                <StyledInput
                    type={meta.type ? meta.type : ''}
                    placeholder={meta.label}
                    rows={7}
                    {...handler()}
                />
            </div>
            }

            {meta.style === 'simple' &&
            <div>
                <SimpleStyledInput
                    type={meta.type ? meta.type : ''}
                    placeholder={meta.label}
                    rows={7}
                    {...handler()}
                />
            </div>
            }
            <span>
                {touched
                && hasError("required")
                && `Pole ${meta.label} jest wymagane.`}

                {touched
                && hasError("minLength")
                && `Minimalna długość ${meta.label} wynosi ${meta.minLength} znaków.`}
            </span>
        </TextAreaInputWrapper>
    );
};

export default TextAreaInput;