import axios from "axios"
import {Link} from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import theme from "assets/styles/theme"
import Loader from "components/Loader"
import SectionHeader from "components/SectionHeader"
import TopBar from "components/TopBar"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`

const KnowledgeSection = styled.div`
    padding: 15px;
`

const KnowledgeImage = styled.div`
    min-height: 35vh;
    background: url(${({background}) => (background ? background : "")})
      no-repeat;
    background-size: cover;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-position: center;
`

const KnowledgeCategories = styled.div`
    font-size: 1.3rem;
    display: flex;
    width: 100%;
    left: 0;
    padding: 1rem 0;
    top: 0;
    flex-wrap: wrap;
`

const CategoryName = styled(Link)`
  padding: 0.7rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 15px;
  font-weight: bold;
  background-color: ${theme.color.mainBlueColor};
  color: ${theme.color.secondaryBlueColor};
  box-shadow: 0 0 30px 0 rgba(0,48,255,0.8);
`

const KnowledgeTitle = styled.p`
    position: relative;
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: bold;
`

const KnowledgeDescription = styled.div`
    font-size: 1.4rem;
    max-width: 100%;
    line-height: 1.5;

    blockquote {
        position: relative;
        font-size: 2rem;
        line-height: 1.5em;

        &:before {
            content: '\\201C';
            font-family: sans-serif;
            position: absolute;
            top: 2rem;
            left: -4.0rem;
            font-size: 12rem;
            color: rgba(255,255,255,0.5);
        }

        p {
            text-align: left;
        }
    }

    iframe{
        width: 100%;
        height: 50vw;
    }

    p {
        text-align: justify;
    }

    strong {
        font-weight: bold;
    }

    img {
        max-width: 100%;
    }
`

const KnowledgeExternalURL = styled.button`
    display: block;
    position: relative;
    width: 250px;
    padding: 0;
    text-align: center;
    line-height: 50px;
    color: white;
    border-radius: 30px;
    background: ${theme.color.mainBlueColor};
    margin: 0 auto;
    margin-top: 5rem;
`

class SingleKnowledgePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: false,
      activeCategoryIndex: 0,
      singleArticle: {},
      categories: [],
      mappedCategories: [],
    }
  }

  componentDidMount() {
    this.fetchSingleArticle(this.props.id)

    let activeCategoryIndex = window.history.state ? window.history.state.activeCategoryIndex : null;
    if (activeCategoryIndex) {
      this.setState({
        activeCategoryIndex: activeCategoryIndex,
      })
      window.history.state.activeCategoryIndex = null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
      this.fetchSingleArticle(this.props.id)
    }
  }

  fetchSingleArticle = (id, accessToken = this.props.userProfile.accessToken) => {
    if (accessToken) {
      this.setState({loading: true})
      let params = {
        "access-token": accessToken,
        "expand": "categories",
      }
      axios
        .get(`${process.env.API_URL}/knowledges/${id}`, {params})
        .then(singleArticle => {
          this.setState({
            singleArticle: singleArticle.data,
          })

        }).then(() => this.fetchArticleCategories())
        .catch(error => {
          this.setState({loading: false, error: error})
        })
        .finally(() => {
          this.setState({loading: false})
        })
    }
    return null
  }

  fetchArticleCategories = (accessToken = this.props.userProfile.accessToken) => {
    if (accessToken) {
      this.setState({loading: true})

      let params = {
        "access-token": accessToken,
      }
      axios
        .get(`${process.env.API_URL}/article-categories`, {params})
        .then(categories => {
          this.setState({
            categories: categories.data,
          })
        })
        .catch(error => {
          this.setState({loading: false, error: error})
        })
        .finally(() => {
          this.mapCategories()
          this.setState({loading: false})
        })
    }
    return null
  }

  mapCategories = () => {
    let articleCategories = this.state.singleArticle.categories
    let categories = this.state.categories
    let mappedCategories = []

    categories.map((category, index) => {
        return articleCategories.forEach(item => {
          if (category.categoryId === item.categoryId) {
            mappedCategories.push({
              categoryId: item.categoryId,
              name: item.name,
              activeCategoryIndex: index,
            })
          }
        })
      },
    )

    this.setState({mappedCategories: mappedCategories})
  }


  render() {
    const {loading, mappedCategories, singleArticle, activeCategoryIndex} = this.state
    return (
      <Wrapper>
        {loading ? <Loader/> : null}

        {singleArticle &&
        <>
          <KnowledgeImage background={singleArticle.imageUrl}/>
          <KnowledgeSection>
            <TopBar link="/app/knowledge/" fixed="true"
                    state={{activeCategoryIndex: activeCategoryIndex}}/>

            <KnowledgeCategories>
              {mappedCategories &&
              mappedCategories.map(category => (
                <CategoryName to={"/app/knowledge/"} key={category.categoryId}
                              state={{activeCategoryIndex: category.activeCategoryIndex}}>
                  {category.name}
                </CategoryName>
              ))
              }
            </KnowledgeCategories>

            <KnowledgeTitle>
              {singleArticle.title}
            </KnowledgeTitle>
            <KnowledgeDescription>
              <ReactMarkdown source={singleArticle.content} escapeHtml={false}/>
            </KnowledgeDescription>

            {singleArticle.externalUrl &&
            <KnowledgeExternalURL as='a' href={singleArticle.externalUrl}>
              Czytaj więcej
            </KnowledgeExternalURL>
            }
          </KnowledgeSection>
        </>
        }

        {!singleArticle &&
        <>
          <TopBar link="/app/knowledge/"/>
          <SectionHeader header="Wiedza barmańska"/>
          Coś poszło nie tak, przykro nam!
        </>
        }
      </Wrapper>
    )
  }
}

export default SingleKnowledgePage