import React from 'react';
import styled from "styled-components";
import SectionHeader from "components/SectionHeader";
import TopBar from "components/TopBar";
import SmallHeader from "components/SmallHeader";

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`;

class InfoPage extends React.Component {
    render() {
        return (
            <Wrapper>
                <TopBar link="/app/more/" points={this.props.userProfile.saldo}/>
                <SectionHeader header="O aplikacji"/>
                <SmallHeader subheader='Aplikacja od ludzi baru, dla ludzi baru.'/>
                <div>
                    Za BAR IS US stoją utytułowani barmani i eksperci w dziedzinach koktajli, food & vodka pairingu i
                    wiedzy alkoholowej: Daria Pasieka, Mateusz Szuchnik, Janusz Trendewicz i Dawid Typa.
                    To dzięki nim każdego dnia poznasz nowe receptury i zamienisz swoją wiedzę barmańską na unikalne
                    nagrody.
                </div>
            </Wrapper>
        )
    }
}

export default InfoPage;

