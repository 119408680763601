import React from "react"
import {graphql, StaticQuery} from "gatsby"
import styled from "styled-components"
import Slider from "react-slick"
import Button from "components/Button";
import "assets/styles/loaders.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderWrapper = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    
    .slick-slider{
      width: 100vw;
    }

    .slick-dots{
      bottom: 10vmin;
      left: 10vmin;
      width: 40vmin;
      text-align: left;
      padding: 0.5em 0;
      font-size: 1.3rem;

      li { 
        width: auto;
        height: auto;

        button {
          padding: 1rem 0;
          width: auto;
          height: auto;
        }
      }

      .slick-active button::before{
        background-color: #cccccc;
      }

      li button::before{
        content: '';
        font-size: 1.3rem;
        width: 1rem;
        height: 1rem;
        background-color: #666666;
        border-radius: 50%;
        position: relative;
        display: block;
        opacity: 1;
      }
    }

    .slick-slide{
      width: 100vw;
      height: 100vh;

      .slide__item{
      width: 100vw;
      height: 100vh;
      display: grid !important;
      grid-template-columns: 2rem 1fr 2rem;
      grid-template-rows: 1fr auto auto 1fr;
      grid-gap: 1rem;
      }
    }
`
const CloseSliderLink = styled.div`
    position: absolute;
    bottom: 10vmin;
    right: 10vmin;
    font-weight: bold;
    padding: 1em;
    text-align: center;
    font-size: 1.3rem;
    cursor: pointer;
`

const SlideImage = styled.div`
    background: url(${({background}) => (background ? background : "")})
      no-repeat;
      grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
`

const SlideHeader = styled.div`
    grid-column: 2 / -2;
    grid-row: 2 / 3;
    font-size: 3rem;
    font-weight: bold;
`

const SlideContent = styled.div`
    grid-column: 2 / -2;
    grid-row: 3 / 4;
`

export default class ValuePropositionSlider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpened: false,
        }

        this.dontShowSlider = "dontShowValuePropositionSlider"
    }

    componentDidMount() {
        this.checkIfCanOpenPopup()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.checkIfCanOpenPopup()
        }
    }

    checkIfCanOpenPopup = () => {
        let dontShowSlider = localStorage.getItem(this.dontShowSlider) === "true"

        if (!dontShowSlider) {
            this.setState({
                isOpened: true,
            })
        }
    }

    handleCloseAndForget = () => {
        localStorage.setItem(this.dontShowSlider, true)
        this.setState({
            isOpened: false,
        })
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            variableWidth: true,
        }

        return (
            <StaticQuery
                query={graphql`
                {
                    slide1: file(relativePath: { eq: "slider1.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    },
                    slide2: file(relativePath: { eq: "slider2.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    },
                    slide3: file(relativePath: { eq: "slider3.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                render={data => {
                    if (this.state.isOpened) {
                        return (
                            <SliderWrapper>
                                <Slider {...settings}>
                                    <div className={"slide__item"}>
                                        <SlideImage background={data.slide1.childImageSharp.fluid.src}/>
                                        <SlideHeader>Poznaj BAR IS US</SlideHeader>
                                        <SlideContent>
                                            BAR IS US to aplikacja od ludzi baru dla ludzi baru. Korzystaj z porad
                                            doświadczonych barmanów i rozwijaj swój warsztat.
                                        </SlideContent>
                                    </div>
                                    <div className={"slide__item"}>
                                        <SlideImage background={data.slide2.childImageSharp.fluid.src}/>
                                        <SlideHeader>Rozwijaj się za barem</SlideHeader>
                                        <SlideContent>
                                            Korzystaj z kompendium wiedzy dla barmanów. Poznawaj receptury na nowe
                                            koktajle, techniki barmańskie i sposoby na kreowanie swojego wizerunku.
                                        </SlideContent>
                                    </div>
                                    <div className={"slide__item"}>
                                        <SlideImage background={data.slide3.childImageSharp.fluid.src}/>
                                        <SlideHeader>Wygrywaj nagrody</SlideHeader>
                                        <SlideContent>
                                            Rozwiązuj quizy, bierz udział w konkursach i zdobywaj punkty. Wymieniaj je
                                            w sklepie na nagrody stworzone z myślą o barmanach.
                                            <Button onClick={this.handleCloseAndForget} title="Przejdź do aplikacji"/>
                                        </SlideContent>
                                    </div>
                                </Slider>
                                <CloseSliderLink margin={3} align='left' small={true}
                                                 onClick={this.handleCloseAndForget}>
                                    Pomiń
                                </CloseSliderLink>

                            </SliderWrapper>
                        )
                    } else {
                        return null
                    }
                }}
            />
        )
    }
}