import axios from "axios"
import {Link} from "gatsby"
import React from "react"
import {FieldControl, FieldGroup, FormBuilder} from "react-reactive-form"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import styled from "styled-components"
import theme from "assets/styles/theme"
import Loader from "components/Loader"
import Pagination from "components/Pagination"
import SectionHeader from "components/SectionHeader"
import SwipeableViews from "react-swipeable-views"
import TextInput from "components/Forms/TextInput"
import TopBar from "components/TopBar"
import {checkNotificationPermission} from "services/pushManager"
import FavButton from "../FavButton";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px 15px 90px 15px;
  display: flex;
  flex-direction: column;
`

const KnowledgeSection = styled.div`

`

const KnowledgeLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
`

const KnowledgeWrapper = styled.div`
  width: 100%;
  background: url(${({image}) => (image ? image : "")}) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 250px;
  margin-bottom: 2rem;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  font-size: 2.3rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:hover, &:focus {
    text-decoration: none;
  }
`

const KnowledgeCategories = styled.div`
  position: absolute;
  font-size: 1.3rem;
  display: flex;
  width: 100%;
  left: 0;
  padding: 1.5rem;
  top: 0;
  flex-wrap: wrap;
`

const CategoryName = styled.div`
  padding: 0.7rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 15px;
  background-color: ${theme.color.mainBlueColor};
  box-shadow: 0 0 30px 0 rgba(0, 48, 255, 0.8);
`

const KnowledgeTitle = styled.p`
  position: relative;
  margin: 1rem 0;
`

const StyledTabs = styled(Tabs)`
  margin: 2rem 0;

  .MuiTabs-indicator {
    background-color: ${theme.color.mainBlueColor};
  }
`

const StyledTab = styled(Tab)`
  font-size: 1.3rem !important;
  min-width: 40% !important;
  text-transform: none !important;
  opacity: 1 !important;
  font-family: inherit !important;
  border-bottom: 2px solid !important;

  &.Mui-selected {
    color: ${theme.color.mainBlueColor} !important;
    font-weight: bold !important;
  }
`

class KnowledgePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeCategoryIndex: 0,
      loading: true,
      error: false,
      fetchingDone: false,
      categories: [],
    }

    this.toggleFav = this.toggleFav.bind(this)
  }

  searchForm = FormBuilder.group({
    search: [""],
  })


  componentDidMount() {
    this.fetchKnowledge()

    setTimeout(() => {
      checkNotificationPermission(this.props.userProfile.accessToken)
    }, 3000)

    let activeCategoryIndex = window.history.state.activeCategoryIndex
    if (activeCategoryIndex) {
      this.setState({
        activeCategoryIndex: activeCategoryIndex,
      })
      window.history.state.activeCategoryIndex = null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
      this.fetchKnowledge(this.props.id)
    }
  }

  fetchKnowledge = (accessToken = this.props.userProfile.accessToken) => {
    if (accessToken) {

      this.setState({loading: true})

      let params = {
        "access-token": accessToken,
      }

      axios
        .get(`${process.env.API_URL}/article-categories`, {params})
        .then(categories => {
          this.categories = categories.data

          this.setState({
            categories: categories.data,
          })

          this.searchForm.get("search").valueChanges.subscribe((value) => {
            let params = {
              "access-token": this.props.userProfile.accessToken,
              "expand": "categories&sort=-created_at",
              "filter[or][0][title][like]": value,
              "filter[or][1][content][like]": value,
            }

            axios
              .get(`${process.env.API_URL}/knowledges`, {params})
              .then(knowledgeItems => {
                this.setState({
                  knowledgeItems: knowledgeItems.data.items,
                })
              })
              .catch(error => {
                this.setState({loading: false, error: error})
              })
          })
        })
        .then((categories = this.state.categories) => {
            let fetchingKnowledges = categories.map(item => this.fetchArticlesByCategory(item.categoryId))

            let results = Promise.all(fetchingKnowledges)

            results.then(() => this.setState({
              loading: false,
              fetchingDone: true
            }))
          },
        )
        .catch(error => {
          this.setState({loading: false, error: error})
        })
    }
    return null

  }

  fetchArticlesByCategory = (categoryId, pageOfItems, accessToken = this.props.userProfile.accessToken) => {

    if (accessToken) {


      let params = {
        "access-token": accessToken,
        "filter[categoryFilter]": categoryId,
        "sort": "-created_at",
      }

      if (pageOfItems) {
        params.page = pageOfItems
        this.setState({loading: true})
      }

      return axios
        .get(`${process.env.API_URL}/knowledges`, {params})
        .then(knowledgeItems => {
          this.setState(state => {
            state.categories.forEach(item => {
              if (item.categoryId === categoryId) {
                item.knowledgeItems = knowledgeItems.data.items
                item.pagination = {
                  currentPage: parseInt(knowledgeItems.headers["x-pagination-current-page"]),
                  pageCount: parseInt(knowledgeItems.headers["x-pagination-page-count"]),
                  itemsPerPage: parseInt(knowledgeItems.headers["x-pagination-per-page"]),
                  totalItems: parseInt(knowledgeItems.headers["x-pagination-total-count"]),
                }
              }
            })
          })
        })
        .then(() => {
          if (pageOfItems) {
            this.forceUpdate()
            this.setState({loading: false})
            window.scrollTo(0, 0)
          }
        })
        .catch(error => {
          this.setState({loading: false, error: error})
        })
    }
    return null
  }

  onChangePage = (pageOfItems, forElement) => {
    this.fetchArticlesByCategory(forElement, pageOfItems)
  }

  handleTabChange = (event, value) => {
    this.setState({
      activeCategoryIndex: value,
    })
  }

  handleTabChangeIndex = activeCategoryIndex => {
    this.setState({
      activeCategoryIndex,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  toggleFav = (ev) => {
    const payload = JSON.parse(ev.currentTarget.value)
    const {id, fav, catId} = payload

    this.setState({loading: true})

    axios({
      method: fav ? 'delete' : 'post',
      url: `${process.env.API_URL}/knowledges/${id}/favourite`,
      params: {"access-token": this.props.userProfile.accessToken}
    })
      .then(response => {
        const categories = [...this.state.categories]
        const catIndex = categories.findIndex((el) => el.categoryId === catId);
        const index = categories[catIndex].knowledgeItems.findIndex((el) => el.articleId === id);

        categories[catIndex].knowledgeItems[index] = {
          ...categories[catIndex].knowledgeItems[index],
          favourite: !fav
        };
        this.setState({
          loading: false,
          categories: categories
        })
      })
      .catch(error => {
        this.setState({loading: false, error: error})
      })
  }

  render() {
    const {loading, activeCategoryIndex, categories, fetchingDone} = this.state
    return (
      <Wrapper>
        <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
        <SectionHeader header="Wiedza barmańska"/>

        {loading ? <Loader/> : null}

        {fetchingDone &&
        <div>
          <StyledTabs value={activeCategoryIndex} variant="scrollable"
                      onChange={this.handleTabChange}>
            {categories.map(item => <StyledTab key={item.categoryId}
                                               label={item.name}/>)}
          </StyledTabs>
          <FieldGroup
            control={this.searchForm}
            render={({get, invalid}) => (
              <form onSubmit={this.handleSubmit}>
                <FieldControl
                  strict={false}
                  name="search"
                  render={TextInput}
                  meta={{
                    label: "Wyszukaj frazę...",
                    style: "simple",
                    padding: "0px"
                  }}
                />
              </form>
            )}
          />
          <SwipeableViews index={activeCategoryIndex}
                          onChangeIndex={this.handleTabChangeIndex}>
            {categories.map(category => {
              console.log(category)
              return (
                <KnowledgeSection key={category.categoryId}>
                  {category.knowledgeItems.map(item => (
                    <KnowledgeWrapper
                      key={item.articleId}
                      image={item.imageUrl}
                      state={{activeCategoryIndex: activeCategoryIndex}}
                    >
                      <FavButton
                        favorite={item.favourite}
                        value={JSON.stringify({
                          id: item.articleId,
                          fav: item.favourite,
                          catId: category.categoryId
                        })}
                        onClick={(ev) => this.toggleFav(ev)}
                      />

                      <KnowledgeLink
                        to={`/app/knowledge-post/${item.articleId}/`}>
                        <KnowledgeCategories>
                          {item.categories &&
                          item.categories.map(category => (
                            <CategoryName
                              key={category.categoryId}>{category.name}</CategoryName>
                          ))
                          }
                        </KnowledgeCategories>

                        <KnowledgeTitle>{item.title}</KnowledgeTitle>
                      </KnowledgeLink>

                    </KnowledgeWrapper>
                  ))}

                  {category.knowledgeItems.length === 0 &&
                  <div>Obecnie w tej kategorii nie ma żadnych artykułów. Sprawdź
                    za jakiś czas.</div>
                  }

                  {category.pagination &&
                  <Pagination currentPage={category.pagination.currentPage}
                              pageCount={category.pagination.pageCount}
                              onChangePage={this.onChangePage}
                              forElement={category.categoryId}/>
                  }
                </KnowledgeSection>
              )
            })}
          </SwipeableViews>
        </div>
        }
      </Wrapper>
    )
  }
}

export default KnowledgePage