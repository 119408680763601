import React from 'react';
import Switch from "react-switch";
import styled from "styled-components"

const SwitchWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

class SwitchInput extends React.Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            arePropsGiven: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.arePropsGiven) {
            this.setState({
                checked: this.props.initialValue,
                arePropsGiven: true
            })
        }
    }

    handleChange(checked) {
        this.setState({checked: checked}, () => {
            this.props.onChange(checked);
        })
    }

    render() {
        return (<>
            <SwitchWrapper>
                <span>{this.props.label}</span>
                <Switch
                    onChange={this.handleChange}
                    checked={this.state.checked}
                    onColor="#0030aa"
                    onHandleColor="#0030ff"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                />
            </SwitchWrapper>
        </>);
    }
}

export default SwitchInput;
