import axios from "axios"
import React from "react"
import {navigate} from "gatsby-link"
import styled from "styled-components"
import {FieldControl, FieldGroup, FormBuilder, Validators} from "react-reactive-form"
import Button from "components/Button"
import Loader from "components/Loader"
import AutocompleteInput from "components/Forms/AutocompleteInput"
import SectionHeader from "components/SectionHeader"
import SelectInput from "components/Forms/SelectInput"
import SmallHeader from "components/SmallHeader"
import TextAreaInput from "components/Forms/TextAreaInput"
import TextInput from "components/Forms/TextInput"
import TopBar from "components/TopBar"
import {Link} from "gatsby";


const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 150px 15px ;
    display: flex;
    flex-direction: column;
`

const FormWrapper = styled.div`
  margin: 1rem 0;
  position: relative;
  
  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

const HintLink = styled.div`
    margin: 2rem 0;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: relative;
    
    a {
        text-decoration: underline;
        color: white;
    }
`;

class RewardsOrderPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            error: "",
            provinces: [],
            taxOfficeList: [],
        }
    }

    rewardForm = FormBuilder.group({
        taxNumber: ["", Validators.required],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        birthDate: ["", Validators.required],
        address: FormBuilder.group({
            street: ["", Validators.required],
            houseNumber: ["", Validators.required],
            apartmentNumber: [""],
            zipCode: ["", Validators.required],
            city: ["", Validators.required],
            post: ["", Validators.required],
            commune: ["", Validators.required],
            poviat: ["", Validators.required],
            province: ["", Validators.required],
        }),
        phone: ["", Validators.required],
        comment: [""],
        taxOfficeId: ["", Validators.required],
        taxOfficeName: [""],
    })

    componentDidMount() {
        this.fetchProvinces()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userProfile.profileId !== this.props.userProfile.profileId) {
            this.fetchProvinces()
        }
    }

    fetchProvinces = () => {
        const {accessToken} = this.props.userProfile
        if (!accessToken) {
            return
        }

        let params = {
            "access-token": accessToken,
        }

        this.setState({loading: true})
        axios
            .get(`${process.env.API_URL}/provinces`, {params})
            .then(provinces => {
                this.setState({
                    loading: false,
                    provinces: provinces.data,
                }, () => {
                    this.updateState()
                })
            })
            .catch(error => {
                this.setState({loading: false, error: error})
            })
    }

    updateState = () => {
        if (!this.props.userProfile.profileId) {
            return
        }

        let address = {
            street: "",
            houseNumber: "",
            apartmentNumber: "",
            zipCode: "",
            city: "",
        }

        if (this.props.userProfile.address) {
            address = this.props.userProfile.address
        }

        this.rewardForm.setValue({
            taxNumber: this.props.userProfile.taxNumber ? this.props.userProfile.taxNumber : "",
            firstName: this.props.userProfile.firstName ? this.props.userProfile.firstName : "",
            lastName: this.props.userProfile.lastName ? this.props.userProfile.lastName : "",
            birthDate: this.props.userProfile.birthDate ? this.props.userProfile.birthDate : "",
            address: {
                street: address.street ? address.street : "",
                houseNumber: address.houseNumber ? address.houseNumber : "",
                apartmentNumber: address.apartmentNumber ? address.apartmentNumber : "",
                zipCode: address.zipCode ? address.zipCode : "",
                city: address.city ? address.city : "",
                post: address.post ? address.post : "",
                commune: address.commune ? address.commune : "",
                poviat: address.poviat ? address.poviat : "",
                province: address.provinceId ? address.provinceId : "",
            },
            phone: this.props.userProfile.phone ? this.props.userProfile.phone : "",
            comment: "",
            taxOfficeId: this.props.userProfile.taxOffice ? this.props.userProfile.taxOffice.id : "",
            taxOfficeName: this.props.userProfile.taxOffice ? this.props.userProfile.taxOffice.name : "",
        })

        this.rewardForm.get("address.province").meta = {
            label: "Województwo",
            style: "bordered",
            options: this.state.provinces,
        }
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            loading: true,
        })

        let params = {
            "access-token": this.props.userProfile.accessToken,
        }

        let userData = {
            rewards:
                [
                    {
                        rewardId: this.props.id,
                        count: 1,
                    },
                ],
            taxNumber: this.rewardForm.value.taxNumber,
            firstName: this.rewardForm.value.firstName,
            lastName: this.rewardForm.value.lastName,
            birthDate: this.rewardForm.value.birthDate,
            address: {
                street: this.rewardForm.value.address.street,
                houseNumber: this.rewardForm.value.address.houseNumber,
                apartmentNumber: this.rewardForm.value.address.apartmentNumber,
                zipCode: this.rewardForm.value.address.zipCode,
                city: this.rewardForm.value.address.city,
                post: this.rewardForm.value.address.post,
                commune: this.rewardForm.value.address.commune,
                poviat: this.rewardForm.value.address.poviat,
                provinceId: this.rewardForm.value.address.province,
                comment: this.rewardForm.value.comment,
            },
            phone: this.rewardForm.value.phone,
            taxOffice: {
                name: this.rewardForm.value.taxOfficeName,
            },
        }

        if (this.rewardForm.value.taxOfficeId !== 0) {
            userData.taxOffice.id = this.rewardForm.value.taxOfficeId
        }

        axios
            .post(`${process.env.API_URL}/rewards/order`, userData, {params})
            .then(response => {
                if (this.props.updateProfile) {
                    this.props.updateProfile();
                }
                this.setState({
                    error: {},
                    loading: false,
                }, () => {
                    navigate(`app/thank-you/`)
                })
            })
            .catch(error => {
                let errorMessage = ""

                if (error.response) {
                    if (error.response.data) {
                        error.response.data.forEach((field) => {
                            errorMessage += field.message + " "
                        })
                    }

                    this.setState({
                        error: {
                            message: errorMessage,
                        },
                        loading: false,
                    })
                }
            })
    }

    handleTaxOfficeAutocompleteChange = (value, id) => {
        this.rewardForm.setValue({
            ...this.rewardForm.value,
            taxOfficeId: id,
            taxOfficeName: value,
        })

        setTimeout(() => {
            let params = {
                "access-token": this.props.userProfile.accessToken,
                "filter[name][like]": value,
            }
            axios
                .get(`${process.env.API_URL}/tax-offices`, {params})
                .then(taxOfficeList => {
                    this.setState({
                        taxOfficeList: taxOfficeList.data,
                    }, () => {
                        this.forceUpdate()
                    })
                })
                .catch(error => {
                    this.setState({loading: false, error: error})
                })
        })
    }

    render() {
        return (
            <Wrapper>
                {this.state.loading &&
                <Loader/>
                }

                <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                <SectionHeader header="Zamów Nagrodę"/>

                <FormWrapper>
                    <FieldGroup
                        control={this.rewardForm}
                        render={({get, invalid}) => (
                            <form onSubmit={this.handleSubmit}>
                                <SmallHeader subheader={"Dane osobowe"}/>

                                <FieldControl
                                    name="firstName"
                                    render={TextInput}
                                    meta={{label: "Imię", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="lastName"
                                    render={TextInput}
                                    meta={{
                                        label: "Nazwisko",
                                        style: "bordered",
                                        required: true
                                    }}
                                />

                                <FieldControl
                                    name="birthDate"
                                    render={TextInput}
                                    meta={{label: "Data urodzenia", style: "bordered", type: "date", required: true}}
                                />

                                <FieldControl
                                    name="phone"
                                    render={TextInput}
                                    meta={{
                                        label: "Telefon",
                                        style: "bordered",
                                        mask: "+48 999 999 999",
                                        required: true
                                    }}
                                />

                                <SmallHeader subheader={"Dane do wysyłki"}/>

                                <FieldControl
                                    name="address.street"
                                    render={TextInput}
                                    meta={{label: "Ulica", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="address.houseNumber"
                                    render={TextInput}
                                    meta={{label: "Numer domu", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="address.apartmentNumber"
                                    render={TextInput}
                                    meta={{label: "Numer mieszkania", style: "bordered"}}
                                />

                                <FieldControl
                                    name="address.zipCode"
                                    render={TextInput}
                                    meta={{label: "Kod pocztowy", style: "bordered", mask: "99-999", required: true}}
                                />

                                <FieldControl
                                    name="address.city"
                                    render={TextInput}
                                    meta={{label: "Miasto", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="address.post"
                                    render={TextInput}
                                    meta={{label: "Poczta", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="address.commune"
                                    render={TextInput}
                                    meta={{label: "Gmina", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="address.poviat"
                                    render={TextInput}
                                    meta={{label: "Powiat", style: "bordered", required: true}}
                                />

                                <FieldControl
                                    name="address.province"
                                    render={SelectInput}
                                    strict={false}
                                    meta={{
                                        label: "Województwo",
                                        style: "bordered",
                                        options: this.state.provinces,
                                        required: true
                                    }}
                                />

                                <FieldControl
                                    name="comment"
                                    render={TextAreaInput}
                                    meta={{label: "Komentarz", style: "bordered", required: true}}
                                />

                                <SmallHeader subheader={"Dane urzędu skarbowego"}/>

                                <AutocompleteInput
                                    name="taxOfficeId"
                                    value={this.rewardForm.value.taxOfficeName}
                                    id={this.rewardForm.value.taxOfficeId}
                                    placeholder='Urząd Skarbowy'
                                    meta={{required: true}}
                                    onChange={this.handleTaxOfficeAutocompleteChange}
                                    options={this.state.taxOfficeList}
                                />

                                <FieldControl
                                    name="taxNumber"
                                    render={TextInput}
                                    meta={{label: "PESEL", style: "bordered", mask: "99999999999", required: true}}
                                />

                                <HintLink>
                                    <Link to="/app/more/faq/">Dowiedz się, dlaczego prosimy Cię o Urząd Skarbowy i
                                        PESEL</Link>
                                </HintLink>

                                <Button
                                    type="submit"
                                    disabled={invalid}
                                    title="Zamów"
                                />

                                <HintLink>
                                    * pole obowiązkowe
                                </HintLink>
                            </form>
                        )}
                    />

                    {this.state.error &&
                    <div className="error">
                        <div>{this.state.error.message}</div>
                    </div>
                    }
                </FormWrapper>
            </Wrapper>
        )
    }
}

export default RewardsOrderPage