import React from "react"
import styled from "styled-components"
import { useField } from "formik"
import InputMask from "react-input-mask"
import theme from "assets/styles/theme"

const StyledInputWrapper = styled.div`
    margin-bottom: 1rem;
    padding: ${({ padding }) => (padding ? padding : "0 24px")};
`

const StyledInput = styled(InputMask)`
    position: relative;
    width: 100%;
    border-radius: ${theme.radius.input};
    border: 1px solid ${theme.color.inputBorderColor};
    padding: ${theme.padding.input};
    background: none transparent !important;
    color: white;
    text-align: center;
    margin-bottom: 1rem;

    &[type="date"] {
        -webkit-appearance: none;

        &:before {
            content: ${({ date }) => (date ? date : "\"Data urodzenia\"")};
            text-align: center;
            width: 100%;
            color: ${theme.color.placeholderColor};
            margin-left: 1rem;
        }

        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
    }

    &:focus {
      outline: none;

      &:before {
            content: none;
        }
    }
`

const StyledSelect = styled.select`
    position: relative;
    width: 100%;
    border-radius: ${theme.radius.input};
    border: 1px solid ${theme.color.inputBorderColor};
    padding: ${theme.padding.input};
    background: none transparent !important;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
`

export const ErrorMessage = styled.div`
    color: red;
    font-weight: bold;
    text-align: center;
    margin: 0;
`

const StyledLabel = styled.label`
`

export const Select = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return (
        <StyledInputWrapper>
            <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
            <StyledSelect {...field} {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </StyledInputWrapper>
    )
}

export const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return (
        <StyledInputWrapper>
            <label htmlFor={props.id || props.name}>{label}</label>
            <StyledInput className="text-input" {...field} {...props} mask={props.mask}/>
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </StyledInputWrapper>
    )
}