import React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby";
import HomeIcon from "assets/images/svg/home.svg";
import ContestsIcon from "assets/images/svg/konkursy.svg";
import QuizesIcon from "assets/images/svg/quizy.svg";
import RewardsIcon from "assets/images/svg/nagrody.svg";
import MoreIcon from "assets/images/svg/wiecej.svg";
import HeartIcon from "assets/images/svg/icon-heart-empty.svg";

const Navigation = styled.nav`
  position: fixed;
  bottom: -1px;
  left: 0;
  min-height: 90px;
  width: 100%;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: -1px;
    right: 0;
    background: inherit;
    box-shadow: inset 0 0 0 200px rgba(21,11,35,0.8);
    filter: blur(1px);
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
`;

const StyledLink = styled(Link)`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.4rem;
    
    svg{
        width: 25px
    }

    span {
      display: none;
    }

    &[aria-current="page"] {
        background: rgba(29,29,43, 0.5);
        border-radius: 20px;

        span {
          display: inline-block;
          margin-left: 1rem;
        }
    }
`;

const Navbar = props => {
    return (
        <Navigation>
            <NavWrapper>
                <StyledLink
                    to="/app/dashboard/">
                    <HomeIcon/>
                    <span>Start</span>
                </StyledLink>

                <StyledLink
                    to="/app/quizes/">
                    <QuizesIcon/>
                    <span>Quizy</span>
                </StyledLink>

                <StyledLink
                    to="/app/rewards/">
                    <RewardsIcon/>
                    <span>Nagrody</span>
                </StyledLink>

                <StyledLink
                  to="/app/favorites/">
                    <HeartIcon/>
                    <span>Ulubione</span>
                </StyledLink>

                <StyledLink
                    to="/app/more/">
                    <MoreIcon/>
                    <span>Więcej</span>
                </StyledLink>
            </NavWrapper>
        </Navigation>
    );
};

export default Navbar;
