import React from "react"
import ReactMarkdown from "react-markdown"
import 'assets/styles/faq-details.css';

export default class Details extends React.Component {
    render() {
        const {title, content} = this.props.item

        return (
            <details className="question">
                <summary className="question__title">
                    {title}
                </summary>
                <div className="question__answer">
                    <ReactMarkdown source={content}/>
                </div>
            </details>
        )
    }
}
