import React from "react"
import {graphql, StaticQuery} from "gatsby"
import styled from "styled-components"
import Button from "components/Button";

const PopupWrapper = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 9999;
    justify-content: center;
    align-items: center;
`

const PopupContent = styled.div`
    background: url(${({background}) => (background ? background : "")})
        no-repeat;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
`

const CloseSliderLink = styled.div`
    position: absolute;
    bottom: 10vmin;
    right: 10vmin;
    font-weight: bold;
    padding: 1em;
    text-align: center;
    font-size: 1.3rem;
    cursor: pointer;
`

const SlideHeader = styled.div`
    font-size: 3rem;
    font-weight: bold;
    margin: 2rem 0;
`

export default class RecommendPopup extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpened: false,
        }

        this.dontShowPopup = "dontShowRecommendPopup"
    }

    componentDidMount() {
        this.checkIfCanOpenPopup()
    }

    checkIfCanOpenPopup = () => {
        let dontShowPopup = localStorage.getItem(this.dontShowPopup) === "true"

        if (!dontShowPopup) {
            this.setState({
                isOpened: true,
            })
        }
    }

    handleClose = () => {
        this.setState({
            isOpened: false,
        })
    }

    handleCloseAndForget = () => {
        localStorage.setItem(this.dontShowPopup, true)

        this.setState({
            isOpened: false,
        })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                {
                    recommendImage: file(relativePath: { eq: "recommend.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    },
                }
                `}
                render={data => {
                    if (this.state.isOpened) {
                        return (
                            <PopupWrapper>
                                <PopupContent background={data.recommendImage.childImageSharp.fluid.src}>
                                    <SlideHeader>Buduj społeczność barmanów razem z nami</SlideHeader>
                                    <div>
                                        Poleć BAR IS US znajomym barmanom i zgarnij 50 punktów za polecenie.
                                    </div>
                                    <Button onClick={this.handleClose} title="OK"/>
                                </PopupContent>
                                <CloseSliderLink margin={3} align='left' small={true}
                                                 onClick={this.handleCloseAndForget}>
                                    Nie pokazuj więcej
                                </CloseSliderLink>

                            </PopupWrapper>
                        )
                    } else {
                        return null
                    }
                }}
            />
        )
    }
}
