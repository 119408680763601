import React from "react"
import axios from "axios"
import styled from "styled-components"
import Loader from "components/Loader"
import SectionHeader from "components/SectionHeader"
import TopBar from "components/TopBar"
import Details from "components/Details"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 90px 15px;
    display: flex;
    flex-direction: column;
`

export default class FaqPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            error: false,
            faqItems: [],
        }
    }

    componentDidMount() {
        this.fetchFaq()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchFaq()
        }
    }

    fetchFaq = () => {
        if (!this.props.userProfile.accessToken) {
            return;
        }

        this.setState({loading: true})

        let params = {
            "access-token": this.props.userProfile.accessToken,
            "per-page": 50,
        }

        axios
            .get(`${process.env.API_URL}/faq-applications`, { params })
            .then(faqItems => {
                this.setState({
                    loading: false,
                    faqItems: faqItems.data,
                })
            })
            .catch(error => {
                this.setState({loading: false, error: error})
            })
    }

    render() {
        const {loading, error, faqItems} = this.state

        return (
            <Wrapper>
                <TopBar link="/app/more/" points={this.props.userProfile.saldo}/>
                <SectionHeader header="FAQ / Pomoc"/>
                {loading && <Loader/>}
                {error ? (
                    <div className="error">
                        Ups! Coś poszło nie tak...
                        <p>Komunikat: {error.response.statusText}</p>
                    </div>
                ) : (
                    faqItems.length > 0 ? (
                        <section className="questions">
                            { this.state.faqItems.map(item => <Details key={item.articleId} item={item}/>) }
                        </section>
                    ) : (
                        <p>Brak pytań do pokazania. Kiedyś na pewno się pojawią!</p>
                    )
                )}

            </Wrapper>
        )
    }
}