import React from 'react';
import styled from "styled-components";
import {Link} from "gatsby";
import LeftArrow from "assets/images/svg/left-arrow--black.svg";
import RightArrow from "assets/images/svg/right-arrow.svg";
import TopBar from "components/TopBar";
import {checkNotificationPermission} from "services/pushManager"
import RecommendPopup from "components/RecommendPopup";

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px  150px 15px;
    display: flex;
    flex-direction: column;
`;

const SignOutWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 150px;
    justify-content: center;
    background-color: white;
    color: black;
    font-weight: bold;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    font-size: 1.4rem;
    padding-top: 2rem;
    
    span {
      margin-left: 1rem;
    }
`;

const StyledLink = styled(Link)`
    color: white;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid white;
    align-items: center;
    margin: 1.5rem 0;
`;

class MorePage extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            checkNotificationPermission(this.props.userProfile.accessToken)
        }, 3000)
    }

    render() {
        const firebase = require("firebase");

        return (
            <Wrapper>
                <TopBar link="/app/dashboard/" name={this.props.userProfile.firstName + " " + this.props.userProfile.lastName } points={this.props.userProfile.saldo}/>

                <StyledLink
                    to="/app/more/account/">
                    <p>Moje konto</p>
                    <RightArrow/>
                </StyledLink>
                <StyledLink
                    to="/app/more/saldo/">
                    <p>Historia punktów</p>
                    <RightArrow/>
                </StyledLink>
                <StyledLink
                    to="/app/more/faq/">
                    <p>FAQ / Pomoc</p>
                    <RightArrow/>
                </StyledLink>
                <StyledLink
                    to="/app/more/info/">
                    <p>Informacje o aplikacji</p>
                    <RightArrow/>
                </StyledLink>
                <StyledLink
                    to="/app/more/contact/">
                    <p>Kontakt / Zgłoś błąd</p>
                    <RightArrow/>
                </StyledLink>
                <StyledLink
                    to="/app/more/recommend/">
                    <p>Poleć aplikację</p>
                    <RightArrow/>
                </StyledLink>
                <SignOutWrapper onClick={event => {
                    event.preventDefault();
                    firebase.auth().signOut()
                }}>
                    <LeftArrow/>
                    <span>
                        Wyloguj się
                    </span>
                </SignOutWrapper>
                <RecommendPopup/>
            </Wrapper>
        )
    }
}

export default MorePage;

