import React from "react"
import axios from "axios"
import styled from "styled-components"
import Snackbar from "@material-ui/core/Snackbar" //TODO: Snackbar do zewnętrznego komponentu
import { FieldControl, FieldGroup, FormBuilder, Validators } from "react-reactive-form"
import Button from "components/Button"
import Loader from "components/Loader"
import SectionHeader from "components/SectionHeader"
import TextInput from "components/Forms/TextInput"
import TextAreaInput from "components/Forms/TextAreaInput"
import TopBar from "components/TopBar"

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
`

const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;
  
  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`


class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSnackbar: false,
      loading: false,
    }
  }

  contactForm = FormBuilder.group({
    subject: ["", Validators.required],
    body: ["", Validators.required],
  })

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      loading: true,
    })

    axios
      .post(`${process.env.API_URL}/contact?access-token=${this.props.userProfile.accessToken}`, {
        subject: this.contactForm.value.subject,
        body: this.contactForm.value.body,
      })
      .then(response => {
        this.setState({
          error: {},
          showSnackbar: true,
          loading: false,
        }, () => {
            this.contactForm.reset();
        })
      })
      .catch(error => {
        let errorMessage = ""

        if (error.response) {
          error.response.data.forEach((field) => {
            errorMessage += field.message + " "
          })
        } else {
          errorMessage = "Coś poszło nie tak. Skontaktuj się z obsługą techniczną.";
        }

        this.setState({
          error: {
            message: errorMessage,
          },
          loading: false,
        })
      })
  }

  handleSnackbarClose = () => {
    this.setState({
      showSnackbar: false,
    })
  }

  render() {
    const {loading, error, showSnackbar} = this.state;

    return (
      <Wrapper>
        {loading && <Loader/>}

        <TopBar link="/app/more/" points={this.props.userProfile.saldo}/>
        <SectionHeader header="Kontakt / Zgłoś błąd"/>

        <FormWrapper>
          <FieldGroup
            control={this.contactForm}
            render={({ get, invalid }) => (
              <form onSubmit={this.handleSubmit}>
                <FieldControl
                  name="subject"
                  render={TextInput}
                  meta={{ label: "Temat", style: "bordered" }}
                />

                <FieldControl
                  name="body"
                  render={TextAreaInput}
                  meta={{
                    label: "Wiadomość",
                    style: "bordered",
                  }}
                />

                <Button
                  type="submit"
                  disabled={invalid}
                  title="Wyślij"
                />
              </form>
            )}
          />

          {error &&
          <div className="error">
            <div>{error.message}</div>
          </div>
          }

          <Snackbar
            className="success"
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={showSnackbar}
            autoHideDuration={2000}
            onClose={this.handleSnackbarClose}
            message={<span>Dziękujemy za wysłanie wiadomości</span>}
          />
        </FormWrapper>
      </Wrapper>
    )
  }
}

export default Contact