import React from "react";
import styled from "styled-components";
import theme from "assets/styles/theme";
import PropTypes from "prop-types";

import Heart from 'assets/images/svg/icon-heart.svg'

const StyledButton = styled.button`
  width: 2em;
  height: 2em;
  border-radius: 2em;
  padding: 0.5em;
  position: absolute;
  top: 15px;
  left: auto;
  right: 15px;
  z-index: 10;
  color: #ffffff;
  border: 1px solid currentcolor;
  background: none transparent;
  transition: color 300ms;
  cursor: pointer;
  
  svg{
    width: 100%;
  }

  svg path {
    fill: ${({favorite}) => (favorite ? "currentcolor" : "none")};
    stroke:  currentcolor;
  }

  &:hover {
    color: ${theme.color.mainBlueColor};
  }
`;

const FavButton = props => {
  const handleClick = (ev) => {
    if (props.onClick) props.onClick(ev)
  }

  return <StyledButton
    type="button"
    className={props.className}
    onClick={ev => handleClick(ev)}
    value={props.value}
    disabled={props.disabled}
    favorite={props.favorite}
  >
    <Heart/>
  </StyledButton>
}

FavButton.defaultProps = {
  value: ``,
  favorite: false,
}

FavButton.propTypes = {
  value: PropTypes.string,
  favorite: PropTypes.bool,
}

export default FavButton
