import * as Yup from "yup"
import axios from "axios"
import React from "react"
import {Form, Formik} from "formik"
import {ErrorMessage, Select, TextInput} from "./FormikInputs"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "../Button"
import Loader from "../Loader"
import SmallHeader from "../SmallHeader"
import AutocompleteInput from "./AutocompleteInput"
import styled from "styled-components";
import {Link} from "gatsby";

const HintLink = styled.div`
    margin: 2rem 0;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: relative;
    
    a {
        text-decoration: underline;
        color: white;
    }
`;

const ValidationMessages = {
    required: "Pole jest wymagane",
    peselCharCount: "Pole PESEL musi mieć 11 znaków",
}

const sendUserData = (data, accessToken, profileId) => {
    let params = {
        "access-token": accessToken,
        "expand": "saldoHistory,questionnaires,address,contestToUser.contest,welcomepack,taxOffice",
    }

    let userAddress = {
        taxNumber: data.taxNumber,
        address: {
            street: data.street,
            houseNumber: data.houseNumber,
            apartmentNumber: data.apartmentNumber,
            zipCode: data.zipCode,
            city: data.city,
            post: data.post,
            commune: data.commune,
            poviat: data.poviat,
            provinceId: parseInt(data.provinceId),
        },
        taxOffice: {
            name: data.taxOfficeName,
        },
    }
    if (data.taxOfficeId !== 0) userAddress.taxOffice.id = data.taxOfficeId

    return axios.patch(`${process.env.API_URL}/profiles/${profileId}`, userAddress, {params})
}

const handleTaxOfficeAutocompleteChange = (value, accessToken) => {

    let params = {
        "access-token": accessToken,
        "filter[name][like]": value,
    }

    return axios
        .get(`${process.env.API_URL}/tax-offices`, {params})
        .then(response => {
                if (response.status === 200) {
                    return response.data
                }
            },
        )
}

const FormAddressData = (props) => {
    const {taxNumber, taxOffice, accessToken, profileId} = props.userProfile
    let {street, houseNumber, apartmentNumber, zipCode, city, post, commune, poviat, provinceId} = props.userProfile.address ? props.userProfile.address : "";

    return (
        <Formik
            enableReinitialize
            initialValues={{
                street: street ? street : "",
                houseNumber: houseNumber ? houseNumber : "",
                apartmentNumber: apartmentNumber ? apartmentNumber : "",
                zipCode: zipCode ? zipCode : "",
                city: city ? city : "",
                post: post ? post : "",
                commune: commune ? commune : "",
                poviat: poviat ? poviat : "",
                provinceId: provinceId ? provinceId : "",
                taxOfficeId: taxOffice ? taxOffice.id : "",
                taxOfficeName: taxOffice ? taxOffice.name : "",
                taxNumber: taxNumber ? taxNumber : "",
            }}
            validationSchema={Yup.object({
                street: Yup.string()
                    .required(ValidationMessages.required),
                houseNumber: Yup.string()
                    .required(ValidationMessages.required),
                zipCode: Yup.string()
                    .required(ValidationMessages.required),
                city: Yup.string()
                    .required(ValidationMessages.required),
                post: Yup.string()
                    .required(ValidationMessages.required),
                commune: Yup.string()
                    .required(ValidationMessages.required),
                poviat: Yup.string()
                    .required(ValidationMessages.required),
                provinceId: Yup.string()
                    .required(ValidationMessages.required),
                taxOfficeId: Yup.string(),
                taxNumber: Yup.string()
                    .min(11, ValidationMessages.peselCharCount)
                    .max(11, ValidationMessages.peselCharCount),
            })}
            onSubmit={async (values, actions) => {
                await sendUserData(values, accessToken, profileId)
                    .then(response => {
                        if (response.status === 200) {
                            actions.setStatus("success")

                            setTimeout(() => {
                                props.setProfile(response.data)
                            }, 2000)
                        }
                    })
                    .catch(error => {
                        actions.setStatus("error")
                        if (error.response.data.message) {
                            actions.setFieldError("general", error.response.data.name)
                        } else if (error.response.data) {
                            error.response.data.forEach((item) => {
                                actions.setFieldError(item.field, item.message)
                            })
                        }
                    })
                    .finally(() => {
                        setTimeout(() => actions.setStatus(""), 2000)
                    })
            }}
        >
            {formikProps => (
                <Form>
                    <Snackbar
                        className={typeof (formikProps.status) !== "object" ? formikProps.status : ""}
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        open={formikProps.status !== ""}
                        autoHideDuration={2000}
                        message={<>
                            {formikProps.status === 'success' &&
                            <span>Pomyślnie zaktualizowano</span>
                            }

                            {formikProps.status === 'error' &&
                            <span>Wprowadzone dane są nieprawidłowe</span>
                            }
                        </>}
                    />
                    <SmallHeader subheader={"Dane adresowe"}/>
                    <TextInput name="street" type="text" placeholder="Ulica *"/>
                    <TextInput name="houseNumber" type="text" placeholder="Numer domu *"/>
                    <TextInput name="apartmentNumber" type="text" placeholder="Numer mieszkania"/>
                    <TextInput name="zipCode" type="text" placeholder="Kod pocztowy *" mask={"99-999"}/>
                    <TextInput name="city" type="text" placeholder="Miasto *"/>
                    <TextInput name="post" type="text" placeholder="Poczta *"/>
                    <TextInput name="commune" type="text" placeholder="Gmina *"/>
                    <TextInput name="poviat" type="text" placeholder="Powiat *"/>
                    <Select name="provinceId" type="select" placeholder="Województwo *">
                        <option>Województwo</option>
                        {props.provinces.map((province) => <option key={province.id}
                                                                   value={province.id}>{province.name}</option>)}
                    </Select>

                    <SmallHeader subheader={"Urząd Skarbowy"}/>
                    <AutocompleteInput
                        name="taxOfficeId"
                        autosuggestId="taxOffice_autocomplete"
                        value={formikProps.initialValues.taxOfficeName}
                        id={formikProps.initialValues.taxOfficeId}
                        placeholder="Urząd Skarbowy"
                        onChange={(value, id) => {
                            formikProps.setFieldValue("taxOfficeId", id)
                            formikProps.setFieldValue("taxOfficeName", value)
                            handleTaxOfficeAutocompleteChange(value, accessToken)
                                .then(data => {
                                        formikProps.setStatus({"taxOfficeList": data})
                                    },
                                )
                        }}
                        options={formikProps.status ? formikProps.status.taxOfficeList : []}
                    />
                    <TextInput name="taxNumber" type="text" placeholder="PESEL" mask={"99999999999"}/>

                    {formikProps.errors.general &&
                    <ErrorMessage>
                        {formikProps.errors.general}
                    </ErrorMessage>
                    }
                    {formikProps.isSubmitting ? <Loader/> : null}

                    <HintLink>
                        <Link to="/app/more/faq/">Dowiedz się, dlaczego prosimy Cię o Urząd Skarbowy i
                            PESEL</Link>
                    </HintLink>

                    <Button type="submit" title={"Zapisz dane adresowe"} disabled={formikProps.isSubmitting}/>

                    <HintLink>
                        * pole obowiązkowe
                    </HintLink>
                </Form>
            )}
        </Formik>
    )
}
export default FormAddressData