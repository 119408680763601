import React from "react"
import styled from "styled-components"
import axios from "axios"
import TopBar from "components/TopBar"
import SectionHeader from "components/SectionHeader"
import Question from "components/Question"
import ButtonLink from "components/ButtonLink"
import theme from "assets/styles/theme"
import Loader from "components/Loader"
import TimeLeft from "components/TimeLeft"
import VerifiedPopup from "components/VerifiedPopup";
import PushPopup from "components/PushPopup";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`

const QuizSection = styled.div`
    padding: 15px;

    .question-counter {
      margin-top: 3rem;
      text-align: center;
      font-weight: bold;
    }
`

const QuizTitle = styled.div`
    margin: 1.5rem 0;
    font-size: 2.3rem;
    width: 100%;
    text-align: center;
`

const QuizSummary = styled.div`
    width: 100%;
    text-align: center;
    margin: 2em 0;
`

const ArchivedInfo = styled.p`
    text-align: center;

    span{
        display: block;
        padding: ${theme.padding.input};
        border: 1px solid ${theme.color.inputBorderColor};
        border-radius: ${theme.radius.input};
        margin: 1em 0;
    }
`

const ButtonBack = styled(ButtonLink)`
display: inline-block;
margin: 0;
`

const PointsWrapper = styled.div`
    text-align: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 10px solid ${theme.color.mainBlueColor};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    margin: 3rem auto;
`


const TimeLeftCounter = styled(TimeLeft)`
  font-size: 1.3rem;
  font-weight: bold;
`

class SingleQuizPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            error: false,
            readOnly: false,
            singleQuiz: {},
            currentQuestion: 0,
            isLastQuestion: false,
            showBackButton: true,
            totalPoints: -1,
            userAnswers: {
                questionnaireId: 0,
                questions: [],
            },
        }

        this.isQuestionnaireStarted = false
    }

    componentDidMount() {
        this.fetchSingleQuiz(this.props.id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchSingleQuiz(this.props.id)
        }
    }

    fetchSingleQuiz = (id) => {
        if (!this.props.userProfile.accessToken) {
            return
        }

        this.setState({ loading: true })

        let params = {
            "access-token": this.props.userProfile.accessToken,
            "expand": "questions.answers.answers",
        }

        axios
            .get(`${process.env.API_URL}/questionnaires/${id}`, { params })
            .then(singleQuiz => {
                let numberOfQuestions = singleQuiz.data.questions.length
                let currentQuestion = this.state.currentQuestion

                let today = new Date()
                let dateFrom = new Date(singleQuiz.data.dateFrom)
                let dateTo = singleQuiz.data.dateTo ? new Date(singleQuiz.data.dateTo) : new Date(8640000000000000)

                let readOnly = false
                let isAfterFrom = dateFrom < today
                let isAfterTo = dateTo < today

                if (singleQuiz.data.isSolved || (isAfterFrom && isAfterTo)) {
                    readOnly = true
                }

                this.setState({
                    loading: false,
                    singleQuiz: singleQuiz.data,
                    isLastQuestion: currentQuestion === numberOfQuestions - 1,
                    readOnly: readOnly,
                    userAnswers: {
                        questionnaireId: id,
                        questions: [],
                    },
                })
            })
            .catch(error => {
                this.setState({ loading: false, error: error })
            })
    }

    handleNextQuestion = (answers) => {
        let numberOfQuestions = this.state.singleQuiz.questions.length
        let currentQuestion = this.state.currentQuestion

        currentQuestion += 1

        let questionAnswers = this.state.userAnswers.questions
        questionAnswers.push(answers)

        // update answers
        this.setState({
            currentQuestion: currentQuestion,
            isLastQuestion: currentQuestion === numberOfQuestions - 1,
            userAnswers: {
                ...this.state.userAnswers,
                questions: questionAnswers,
            },
        }, () => {
            // Send answers to API - get totalPoints
            if (currentQuestion >= numberOfQuestions && !this.state.readOnly) {

                let params = {
                    "access-token": this.props.userProfile.accessToken,
                }

                axios
                    .patch(`${process.env.API_URL}/questionnaire-to-users`,
                        this.state.userAnswers,
                        { params },
                    )
                    .then(response => {
                        this.setState({
                            totalPoints: response.data.points,
                        }, () => {
                            this.props.updateProfile()
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        })
    }

    handleQuestionStart = (hasStarted) => {
        let showBackButton = true

        if (this.state.currentQuestion === 0 && hasStarted) {
            showBackButton = false

            let params = {
                "access-token": this.props.userProfile.accessToken,
            }

            axios
                .post(`${process.env.API_URL}/questionnaire-to-users`,
                    this.state.userAnswers,
                    { params },
                )
                .then(response => {
                    this.isQuestionnaireStarted = true
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (this.state.currentQuestion > 0) {
            showBackButton = false
        }

        this.setState({
            showBackButton: showBackButton,
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Wrapper>
                    <QuizSection>
                        {this.state.showBackButton &&
                        <TopBar link="/app/quizes/" points={this.props.userProfile.saldo}/>
                        }
                        <SectionHeader header="Quizy"/>
                        <Loader/>
                    </QuizSection>
                </Wrapper>
            )
        } else if (this.state.singleQuiz.name) {
            let { categoryId } = this.state.singleQuiz

            if (this.state.currentQuestion < this.state.singleQuiz.questions.length) {
                return (
                    <Wrapper>
                        <QuizSection>
                            {this.state.showBackButton &&
                            <TopBar link="/app/quizes/" state={this.state.readOnly && { activeCategoryIndex: 3 }}
                                    points={this.props.userProfile.saldo}/>
                            }
                            <SectionHeader header="Quizy"/>
                            <TimeLeftCounter dateTo={this.state.singleQuiz.dateTo}
                                             text={"Do wygaśnięcia Quizu pozostało"}/>

                            <Question
                                readOnly={this.state.readOnly}
                                question={this.state.singleQuiz.questions[this.state.currentQuestion]}
                                isLast={this.state.isLastQuestion}
                                nextQuestionHandler={this.handleNextQuestion}
                                onQuestionStart={this.handleQuestionStart}
                            />
                            <div
                                className="question-counter">{this.state.currentQuestion + 1} / {this.state.singleQuiz.questions.length}</div>
                            {this.state.readOnly &&
                            <ArchivedInfo>
                                <span>Możesz wrócić do Quizu w każdej chwili - jednak rozwiązać możesz go tylko raz!</span>
                                <ButtonBack to={"/app/quizes/"} state={{ activeCategoryIndex: 3 }} title={"Wróć"}/>
                            </ArchivedInfo>
                            }
                        </QuizSection>
                    </Wrapper>
                )
            }

            return (
                <Wrapper>
                    <QuizSection>
                        <TopBar points={this.props.userProfile.saldo}/>
                        <SectionHeader header="Quizy"/>

                        <QuizTitle>{this.state.singleQuiz.name}</QuizTitle>

                        {!this.state.readOnly &&
                        <>
                            <QuizSummary>
                                {this.state.totalPoints >= 0 &&
                                <>

                                    {/*TODO: Animate me!*/}
                                    {this.state.totalPoints > 0 &&
                                    <>
                                        {/*ABC*/}
                                        {categoryId === 1 &&
                                        <>
                                            Widać, że wiesz co i jak. Zdobywasz:
                                        </>
                                        }
                                        {/*DIY*/}
                                        {categoryId === 2 &&
                                        <>
                                            Od razu widać, że umiesz mieszać. Zdobywasz:
                                        </>
                                        }
                                        {/*Match*/}
                                        {categoryId === 3 &&
                                        <>
                                            Gratulujemy! Udało Ci się połączyć pary. Zdobywasz:
                                        </>
                                        }

                                        <PointsWrapper>
                                            {this.state.totalPoints} PKT!
                                        </PointsWrapper>

                                    </>
                                    }
                                    {this.state.totalPoints === 0 &&
                                    <>
                                        Spróbuj kolejnym razem. Zdobywasz:
                                        <PointsWrapper>
                                            {this.state.totalPoints} PKT!
                                        </PointsWrapper>
                                    </>
                                    }
                                </>
                                }

                                {this.state.totalPoints < 0 &&
                                <PointsWrapper>
                                    ...
                                </PointsWrapper>
                                }
                            </QuizSummary>
                            <ButtonLink to='/app/quizes/' title='Zakończ'/>
                            <PushPopup/>
                            <VerifiedPopup user={this.props.user}
                                           userProfile={this.props.userProfile}/>
                        </>
                        }


                    </QuizSection>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper>
                    <QuizSection>
                        <TopBar link="/app/quizes/"/>
                        <SectionHeader header="Quizy"/>
                        Coś poszło nie tak, przykro nam!
                    </QuizSection>
                </Wrapper>
            )
        }
    }
}

export default SingleQuizPage