import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Header = styled.header`
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: ${({center}) => (center ? 'center' : 'left')};
    position: relative;
`;

const SmallHeader = props => {
    return <Header className={props.className} center={props.center} padding={props.padding}>{props.subheader}</Header>;
};

SmallHeader.defaultProps = {
    subheader: `Śródtytuł`
};

SmallHeader.propTypes = {
    subheader: PropTypes.string,
    center: PropTypes.string,
};

export default SmallHeader;
