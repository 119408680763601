import React from 'react';
import styled from "styled-components";
import theme from "assets/styles/theme";


const SelectInputWrapper = styled.div`
    text-align: center;
    margin-bottom: 1rem;
    padding: ${({padding}) => (padding ? padding : '0 24px')};
    
    span {
        margin-top: 1rem;
    }
`;

const StyledInput = styled.select`
    position: relative;
    background-color: transparent !important;
    width: 100%;
    border-radius: ${theme.radius.input};
    border: 1px solid ${theme.color.inputBorderColor};
    background: none;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
    text-align-last: center;
    -webkit-appearance: none;
    padding: ${theme.padding.input};

    option {
        color: black;
    }

    &:focus {
      outline: none;
    }
`;

const SimpleStyledInput = styled(StyledInput)`
    text-align: left;
`;

const SelectInput = ({handler, touched, hasError, meta}) => {
    return (
        <SelectInputWrapper padding={meta.padding}>
            {meta.style === 'bordered' &&
            <div>
                <StyledInput
                    type={meta.type ? meta.type : ''}
                    placeholder={meta.required ? meta.label + ' *' : meta.label}
                    hidden={meta.hidden}
                    {...handler()}
                >
                    <option value="" disabled>
                        Wybierz...
                    </option>
                    {meta.options.map(option => {
                        return (
                            <option key={option.id} value={option.id}>{option.name}</option>
                        )
                    })}
                </StyledInput>
            </div>
            }
            {meta.style === 'simple' &&
            <div>
                <SimpleStyledInput
                    type={meta.type ? meta.type : ''}
                    placeholder={meta.required ? meta.label + ' *' : meta.label}
                    hidden={meta.hidden}
                    {...handler()}
                >
                    <option value="" disabled>
                        Wybierz...
                    </option>
                    {meta.options.map(option => {
                        return (
                            <option key={option.id} value={option.id}>{option.name}</option>
                        )
                    })}
                </SimpleStyledInput>
            </div>
            }

            <span>
                {touched
                && hasError("required")
                && `Pole ${meta.label} jest wymagane.`}
            </span>
        </SelectInputWrapper>
    );
};

export default SelectInput;