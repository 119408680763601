import axios from "axios"
import React from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import theme from "assets/styles/theme"
import FilterIcon from "assets/images/svg/filter.svg"
import SectionHeader from "components/SectionHeader"
import TopBar from "components/TopBar"
import Loader from "components/Loader"
import Pagination from "components/Pagination"
import {checkNotificationPermission} from "services/pushManager"
import ButtonLink from "components/ButtonLink";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 0 90px;
    display: flex;
    flex-direction: column;
`

const RewardsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 ${theme.padding.layout};
`

const RewardsWrapper = styled(Link)`
    margin-bottom: 2rem;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
    font-size: 2.3rem;
    position: relative;
    width: 47%;
    min-height: 220px;
    
    &:hover, &:focus {
        text-decoration: none;    
    }
`

const RewardsImage = styled.div`
    background: url(${({image}) => (image ? image : "")})
      no-repeat;
    background-size: cover;
    background-position: center;
    height: 80%;
    width: 100%;
    border-radius: 15px;
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    }
`

const RewardsTags = styled.div`
    position: absolute;
    font-size: 1.3rem;
    display: flex;
    width: 100%;
    left: 0;
    padding: 1.5rem 0;
    top: 0;
    flex-wrap: wrap;
`

const TagName = styled.div`
  padding: 0.7rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 15px;
  background-color: ${theme.color.mainBlueColor};
  box-shadow: 0 0 30px 0 rgba(0,48,255,0.8);
`

const FilterPanel = styled.details`
  position: relative;
  
  summary{
    text-align: right;
    display: block;
    cursor: pointer;
    user-select: none;
    z-index: 100;
    position: relative;
    padding: 0 ${theme.padding.layout};
      &:focus {
    outline: 0 none;
  }
    
    svg{
      width: 3em;
      height: 3em;
      object-fit: contain;
    }
  }
  
  &[open] summary{
    background-color: #ffffff;
    color: #000000;
    
    svg .st0{
      stroke: #000000;
    }
  }
`

const FilterList = styled.div`
  background-color: #ffffff;
  color: #000000;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 500;
  display: flex;
  flex-direction: column;

  label{
    padding: 2rem 3rem;
    cursor: pointer;
    
    &.active{
      color: ${theme.color.mainBlueColor};
      background: ${theme.color.secondaryBlueColor}
    }
    > input{
    display: none;
    }
  }
`

const FilterListTitle = styled.div`
  padding: 2rem 3rem;
  font-weight: bold;
`

const RewardsTitle = styled.div`
    position: relative;
    margin: 1rem 0;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;  
    font-weight: normal;
    
    .title__name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%; 
        max-width: 100%;
        margin-bottom: 1rem;
    }
    
    .title__points {
        color: ${theme.color.mainBlueColor};
        font-weight: bold;
    }
`

const CategoryList = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 0 ${theme.padding.layout};
  margin: 0 0 ${theme.padding.layout} 0;
  
  label {
    padding: 0.5em 1em;
    margin: 0 0.5em 0 0;
    border-radius: 1.5em;
    background-color:#333333;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    
    > input{
      display: none;
    }
    
    &.active{
      background-color: ${theme.color.mainBlueColor};
    }
  }
`

class RewardsPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            error: false,
            rewardsItems: [],
            filteredRewards: [],
            rewardsCategories: [],
            filterByCategory: 0,
            sort: '-created_at',
            pagination: {
                currentPage: 1,
                pageCount: null,
                itemsPerPage: null,
                totalItems: null,
            },
        }

        this.changeSortState = this.changeSortState.bind(this)
        this.changeFilterState = this.changeFilterState.bind(this)
        this.handleSortByCategory = this.handleSortByCategory.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
    }

    componentDidMount() {
        this.fetchRewards()

        setTimeout(() => {
            checkNotificationPermission(this.props.userProfile.accessToken)
        }, 3000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchRewards()
        }
        if (this.state.sort !== prevState.sort) {
            this.fetchRewards()
        }
        if (this.state.pagination.currentPage !== prevState.pagination.currentPage) {
            this.fetchRewards()
        }
    }

    fetchRewards = () => {
        if (!this.props.userProfile.accessToken) {
            return
        }

        const {sort, pagination: {currentPage}} = this.state
        this.setState({loading: true})

        let params = {
            'access-token': this.props.userProfile.accessToken,
            page: currentPage,
            sort: sort,
            expand: 'categories',
        };

        axios
            .get(`${process.env.API_URL}/rewards`, {params})
            .then(rewardsItems => {
                this.getCategories(rewardsItems.data)
                this.setState({
                    rewardsItems: rewardsItems.data,
                    filteredRewards: rewardsItems.data,
                    rewardsCategories: this.getCategories(rewardsItems.data),
                    pagination: {
                        currentPage: parseInt(rewardsItems.headers["x-pagination-current-page"]),
                        pageCount: parseInt(rewardsItems.headers["x-pagination-page-count"]),
                        itemsPerPage: parseInt(rewardsItems.headers["x-pagination-per-page"]),
                        totalItems: parseInt(rewardsItems.headers["x-pagination-total-count"]),
                    },
                })
            })
            .then(() => {
                this.setState({loading: false})
                window.scrollTo(0, 0)
            })
            .catch(error => {
                this.setState({loading: false, error: error})
            })
    }

    changeSortState(e) {
        this.setState({sort: e.target.value})
    }

    changeFilterState(e) {
        this.setState({filterByCategory: parseInt(e.target.value)}, () => {
            this.handleSortByCategory()
        })
    }

    onChangePage(pageOfItems) {
        this.setState(prevState => ({
                pagination: {
                    ...prevState.pagination,
                    currentPage: pageOfItems,
                }
            })
        )
    }

    handleSortByCategory = () => {
        let items;

        if (this.state.filterByCategory === 0) {
            items = this.state.rewardsItems;
        } else {
            items = this.state.rewardsItems.filter(item => {
                let isItemIncluded = false

                item.categories.forEach(category => {
                    if (this.state.filterByCategory === category.categoryId) {
                        return isItemIncluded = true
                    }
                })

                return isItemIncluded
            })
        }

        this.setState({
            filteredRewards: items,
        })
    }

    getCategories(arr) {
        let unique_categories = []
        const map = new Map()

        arr.forEach((item) => {
            for (const category of item.categories) {
                if (!map.has(category.categoryId)) {
                    map.set(category.categoryId, true)
                    unique_categories.push({
                        categoryId: category.categoryId,
                        name: category.name,
                    })
                }
            }
        })

        return unique_categories
    }

    render() {
        const {loading, filteredRewards, rewardsCategories, sort, filterByCategory, pagination: {currentPage, pageCount}} = this.state

        if (this.props.userProfile.isVerified) {
            return (
                <Wrapper>
                    <TopBar padding link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader padding header="Nagrody"/>
                    {loading && <Loader/>}


                    {filteredRewards.length > 0 && <>

                        {rewardsCategories.length > 0 && <CategoryList>
                            <label key={0} className={filterByCategory === 0 ? "active" : null}>
                                <input type="checkbox" name="filterByCategory" value={0}
                                       onChange={this.changeFilterState}/>
                                Wszystkie
                            </label>
                            {rewardsCategories.map(({categoryId, name}) => {
                                return <label key={categoryId}
                                              className={filterByCategory === categoryId ? "active" : null}>
                                    <input type="checkbox" name="filterByCategory" value={categoryId}
                                           onChange={this.changeFilterState}/>
                                    {name}
                                </label>
                            })}
                        </CategoryList>}

                        <FilterPanel>
                            <summary>
                                <FilterIcon/>
                            </summary>
                            <FilterList>
                                <FilterListTitle>
                                    Sortuj
                                </FilterListTitle>
                                <label className={sort === "-created_at" ? "active" : null}>
                                    <input type="radio" name="sortByDate" value="-created_at"
                                           checked={sort === "-created_at"}
                                           onChange={this.changeSortState}/>
                                    Od najnowszych
                                </label>
                                <label className={sort === "created_at" ? "active" : null}>
                                    <input type="radio" name="sortByDate" value="created_at"
                                           checked={sort === "created_at"}
                                           onChange={this.changeSortState}/>
                                    Od najstarszych
                                </label>
                                <label className={sort === "points" ? "active" : null}>
                                    <input type="radio" name="sortByPoints" value="points"
                                           checked={sort === "points"}
                                           onChange={this.changeSortState}/>
                                    Od najtańszych
                                </label>
                                <label className={sort === "-points" ? "active" : null}>
                                    <input type="radio" name="sortByPoints" value="-points"
                                           checked={sort === "-points"}
                                           onChange={this.changeSortState}/>
                                    Od najdroższych
                                </label>
                            </FilterList>
                        </FilterPanel>

                        <RewardsSection>
                            {filteredRewards.map(item => {
                                return <RewardsWrapper to={`/app/rewards-reward/${item.rewardId}/`} key={item.rewardId}>
                                    <RewardsImage image={item.imageUrl}>
                                        <RewardsTags>
                                            {item.categories && item.categories.map(category => {
                                                return <TagName key={category.categoryId}>{category.name}</TagName>
                                            })}
                                        </RewardsTags>
                                    </RewardsImage>

                                    <RewardsTitle>
                                        <div className="title__name">{item.name}</div>
                                        <div className="title__points">{item.points}pkt</div>
                                    </RewardsTitle>
                                </RewardsWrapper>
                            })}
                        </RewardsSection>

                        <Pagination currentPage={currentPage} pageCount={pageCount} range={3}
                                    onChangePage={this.onChangePage}/>

                    </>}

                    <RewardsSection>
                        {!filteredRewards.length &&
                        <p>Nie mamy żadnych nagród do pokazania. Kiedyś na pewno się pojawią!</p>}
                    </RewardsSection>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper>
                    <TopBar padding link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader padding header="Nagrody"/>
                    {loading && <Loader/>}

                    <RewardsSection>
                        <p>Ups, nagrody nie są jeszcze widoczne. Dostarcz nam zgodę właściciela baru, a zobaczysz co dla
                            Ciebie mamy.</p>
                        <ButtonLink to='/app/more/faq/' title='Dostarcz zgodę'/>
                    </RewardsSection>
                </Wrapper>
            )
        }

    }
}

export default RewardsPage

