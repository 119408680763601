import React from "react"
import styled from "styled-components"
import theme from "assets/styles/theme"
import ArrowLeft from "assets/images/svg/left-arrow.svg"
import ArrowRight from "assets/images/svg/right-arrow.svg"
import TopBar from "components/TopBar"
import SectionHeader from "components/SectionHeader"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 90px 15px;
    display: flex;
    flex-direction: column;
`

const HistoryList = styled.ul`
  list-style: none;
  padding: 0;
  
  li {
    margin: 0 0 1em 0;
    display: flex;
    align-items: center;
    font-weight: bold;

    span { 
      margin: 0 1em 0 0;
      padding: 0.3em;
      border-radius: 0.5em;
      display: flex;
      width: 2em;
      height: 2em;
      justify-content: center;
      align-items: center;
      
      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
  
  .in span {
    background-color: ${theme.color.darkGreen};

    svg path {
      fill: ${theme.color.green} !important;
    }
  }

  .out span{
    background-color: ${theme.color.darkRed};

    svg path {
      fill: ${theme.color.red} !important;
    }
  }
`

const Points = styled.div`
  margin: 1em 0;
  display: block;
  color: #ffffff;

  span{
    border: 2px solid  ${theme.color.mainBlueColor};
    border-radius: 0.3em;
    padding: 0.4em 2em;
    margin: 1em;
  }
`

class SaldoHistory extends React.Component {

  render() {
    const { saldo, saldoHistory } = this.props.userProfile;

    return (
      <Wrapper>
        <TopBar link="/app/more/" points={saldo}/>
        <SectionHeader header="Historia punktów"/>

        {this.props.userProfile.profileId && <>
          <Points to="/app/more/saldo/">
            Twoje punkty
            <span>
              {saldo}
            </span>
          </Points>

          {saldoHistory && <HistoryList>
            {saldoHistory.map((item, index) => {
              let changeClass
              let change = item.change.toString()
              change[0] === "-" ? changeClass = "out" : changeClass = "in"

              return <li key={index} className={changeClass}>
              <span>
                {changeClass === "in" ? <ArrowRight/> : <ArrowLeft/>}
              </span>
                {item.change}pkt - {item.reason}
              </li>
            })}
          </HistoryList>}
        </>}
      </Wrapper>
    )
  }
}

export default SaldoHistory