import React from "react"
import axios from "axios"
import Snackbar from "@material-ui/core/Snackbar" //TODO: Snackbar do zewnętrznego komponentu
import styled from "styled-components"
import { FieldControl, FieldGroup, FormBuilder, Validators } from "react-reactive-form"
import TopBar from "components/TopBar"
import SectionHeader from "components/SectionHeader"
import Button from "components/Button"
import Loader from "components/Loader"
import TextInput from "components/Forms/TextInput"

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
`

const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;
  
  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`


class Recommend extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSnackbar: false,
      loading: false,
      error: false,
    }
  }

  recommendForm = FormBuilder.group({
    target_email: ["", [Validators.required, Validators.email]],
  })

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      loading: true,
    })

    axios
      .post(`${process.env.API_URL}/form/recommend-pwa?access-token=${this.props.userProfile.accessToken}`, {
        targetEmail: this.recommendForm.value.target_email,
      })
      .then(response => {
        this.setState({
          error: {},
          showSnackbar: true,
          loading: false,
        })
        this.recommendForm.reset()
      })
      .catch(error => {
        let errorMessage = ""

        if (error.response) {
          error.response.data.forEach((field) => {
            errorMessage += field.message + " "
          })

          this.setState({
            error: {
              message: errorMessage,
            },
            loading: false,
          })
        } else {
          this.setState({
            error: {
              message: "Coś poszło nie tak!",
            },
            loading: false,
          })
        }
      })
  }

  handleSnackbarClose = () => {
    this.setState({
      showSnackbar: false,
    })
  }

  render() {
    const { loading, error, showSnackbar } = this.state

    return (
      <Wrapper>
        {loading && <Loader/>}

        <TopBar link="/app/more/" points={this.props.userProfile.saldo}/>
        <SectionHeader header="Poleć aplikację"/>
        <div>
          Poleć aplikację znajomemu barmanowi lub barmance i odbierz 50 punktów.
        </div>
        <FormWrapper>
          <FieldGroup
            control={this.recommendForm}
            render={({ get, invalid }) => (
              <form onSubmit={this.handleSubmit}>
                <FieldControl
                  name="target_email"
                  render={TextInput}
                  meta={{ label: "E-mail", style: "bordered" }}
                />

                <Button
                  type="submit"
                  disabled={invalid}
                  title="Wyślij"
                />
              </form>
            )}
          />

          {error &&
          <div className="error">
            <div>{error.message}</div>
          </div>
          }

          <Snackbar
            className="success"
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={showSnackbar}
            autoHideDuration={2000}
            onClose={this.handleSnackbarClose}
            message={<span>Dziękujemy za polecenie naszej aplikacji</span>}
          />
        </FormWrapper>
      </Wrapper>
    )
  }
}

export default Recommend