import React from "react"
import axios from "axios";
import Snackbar from '@material-ui/core/Snackbar'; //TODO: Snackbar do zewnętrznego komponentu
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import {Link} from 'gatsby';
import {FormBuilder, FieldGroup, FieldControl, Validators,} from "react-reactive-form";
import StarIcon from "assets/images/svg/ulubione_icon.svg";
import theme from "assets/styles/theme";
import Button from "components/Button";
import FileInput from "components/Forms/FileInput";
import Loader from "components/Loader";
import SectionHeader from "components/SectionHeader";
import TextAreaInput from "components/Forms/TextAreaInput";
import TextInput from "components/Forms/TextInput";
import TimeLeft from "components/TimeLeft"
import TopBar from "components/TopBar";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
`;

const ContestsTags = styled.div`
    font-size: 1.3rem;
    left: 0;
    padding: 1.5rem 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &.favourite-only {
        justify-content: flex-end;
    }
`;

const TagName = styled.div`
  padding: 0.7em 1em;
  margin: 0.5em 0.5em 0.5em 0;
  border-radius: 15px;
  background-color: ${theme.color.mainBlueColor};
  box-shadow: 0 0 30px 0 rgba(0,48,255,0.8);
  height: auto;
  word-break: break-all;
`;

const FavouriteTag = styled.div`
    width: 50px;
    height: 50px;
    background-color: rgba(255,255,255,0.2);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: #000000;
        width: 50%;
        
        &.favourite {
            fill: #FFFFFF;
        }
    }
`;

const ContestSection = styled.div`
    padding: 15px;

    .error {
        margin-bottom: 2rem;
        text-align: center;
        font-weight: bold;
    }
`;

const ContestImage = styled.div`
    min-height: 35vh;
    background: url(${({background}) => (background ? background : '')})
      no-repeat;
    background-size: cover;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-position: center;
`;

const ContestTitle = styled.p`
    position: relative;
    margin: 1rem 0;
    font-size: 2.5rem;
    font-weight: bold;
    word-break: break-all;
`;

const ContestDescription = styled.div`
    font-size: 1.4rem;
    text-align: justify;
    max-width: 100%;
    line-height: 1.5;
    margin-bottom: 3rem;

    strong {
        font-weight: bold;
    }

    img {
        max-width: 100%;
    }
`;

const Header = styled(ContestTitle)`
    margin: 2rem 0;

    span {
       background:linear-gradient(45deg, #833ab4, #c13584, #e1306c, #fd1d1d, #fdf497);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
`;

const ContestRules = styled(ContestDescription)``;

const StyledLink = styled(Link)`
    color: white;
    text-decoration: underline;
`;

const ContestHashtag = styled(ContestTitle)`
    margin-bottom: 3rem;
`;

const ContestWinnerWrapper = styled.div`
    border-bottom: 2px solid;
    margin-bottom: 2rem;
`;

const ContestWinnerAnswer = styled.div`
    margin-bottom: 1rem;
`;

const ContestWinnerImage = styled(ContestWinnerAnswer)`
    img {
        max-width: 100%;
        max-height: 50vh;
    }
`;
const TimeLeftCounter = styled(TimeLeft)`
  font-weight: bold;
`

class SingleContestPage extends React.Component {
    changeInstagramForm = FormBuilder.group({
        instagram: ["", Validators.required],
    });

    answerForm = FormBuilder.group({
        answer: [""],
        answerFile: [""],
    });

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            singleContest: {},
            showInstagramSnackbar: false,
            showAnswerSnackbar: false,
            showContestForm: true,
            isAfterDateTo: false,
            isAnswerSent: false,
            isFavourite: false,
        };
    }

    componentDidMount() {
        this.fetchSingleContest(this.props.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchSingleContest(this.props.id);
        }
    }

    fetchSingleContest = (id) => {
        if (!this.props.userProfile.accessToken) {
            return;
        }

        this.setState({loading: true});
        axios
            .get(`${process.env.API_URL}/contests/${id}?access-token=${this.props.userProfile.accessToken}&expand=contestToUsers`)
            .then(singleContest => {
                this.setState({
                    loading: false,
                    singleContest: singleContest.data,
                }, () => {
                    let isAnswerSent = false;
                    let isFavourite = false;

                    let today = new Date();
                    let dateTo = new Date(singleContest.data.dateTo);

                    let isAfterDateTo = today > dateTo;

                    let userContests = this.props.userProfile.contestToUser;
                    if (userContests.length > 0) {
                        userContests.forEach(item => {
                            if (item.contestId === parseInt(id)) {
                                if (item.relation === 1) {
                                    isFavourite = true;
                                }

                                if (item.relation === 2) {
                                    isAnswerSent = true;
                                }
                            }
                        })
                    }

                    this.setState({
                        showContestForm: !isAnswerSent && !isAfterDateTo,
                        isFavourite: isFavourite,
                        isAnswerSent: isAnswerSent,
                        isAfterDateTo: isAfterDateTo
                    })
                });
            })
            .catch(error => {
                this.setState({loading: false, error: error});
            });
    };

    handleFavourite = () => {
        this.setState({
            loading: true
        });

        if (this.state.isFavourite) {
            axios
                .delete(`${process.env.API_URL}/contest-to-users/${this.state.singleContest.contestId}?access-token=${this.props.userProfile.accessToken}`, {})
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (this.props.updateProfile) {
                        this.props.updateProfile();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                });
        } else {
            axios
                .post(`${process.env.API_URL}/contest-to-users?access-token=${this.props.userProfile.accessToken}`, {
                    contestId: this.state.singleContest.contestId,
                    relation: 1
                })
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (this.props.updateProfile) {
                        this.props.updateProfile();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                });
        }

        this.setState({
            isFavourite: !this.state.isFavourite
        })
    };

    handleInstagramSubmit = event => {
        event.preventDefault();

        this.setState({
            loading: true
        });

        axios
            .patch(`${process.env.API_URL}/profiles/${this.props.userProfile.profileId}?access-token=${this.props.userProfile.accessToken}`, {
                instagram: this.changeInstagramForm.value.instagram
            })
            .then(response => {
                if (this.props.updateProfile) {
                    this.props.updateProfile();
                }

                this.setState({
                    error: {},
                    showInstagramSnackbar: true,
                    loading: false,
                });
            })
            .catch(error => {
                let errorMessage = "";

                if (error.response.data) {
                    error.response.data.forEach((field) => {
                        errorMessage += field.message + " ";
                    });

                    this.setState({
                        error: {
                            message: errorMessage
                        },
                        loading: false,
                    });
                }
            });
    };

    handleAnswerSubmit = event => {
        event.preventDefault();

        this.setState({
            loading: true
        });

        axios
            .post(`${process.env.API_URL}/contest-to-users?access-token=${this.props.userProfile.accessToken}`, {
                contestId: this.state.singleContest.contestId,
                relation: 2,
                answer: this.answerForm.value.answer,
                image: this.answerForm.value.answerFile
            })
            .then(response => {
                if (this.props.updateProfile) {
                    this.props.updateProfile();
                }

                this.setState({
                    error: {},
                    showAnswerSnackbar: true,
                    showContestForm: false,
                    isAnswerSent: true,
                    loading: false,
                });
            })
            .catch(error => {
                let errorMessage = "";

                if (error.response.data) {
                    error.response.data.forEach((field) => {
                        errorMessage += field.message + " ";
                    });

                    this.setState({
                        error: {
                            message: errorMessage
                        },
                        loading: false
                    });
                }
            });
    };

    handleSnackbarClose = () => {
        this.setState({
            showAnswerSnackbar: false,
            showInstagramSnackbar: false,
        })
    };

    handleFileChange = (value) => {
        this.answerForm.setValue({
            answer: this.answerForm.value.answer,
            answerFile: value,
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <Wrapper>
                    <ContestSection>
                        <TopBar link="/app/contests/"/>
                        <SectionHeader header="Konkursy"/>
                        <Loader/>
                    </ContestSection>
                </Wrapper>
            );
        } else if (this.state.singleContest.name) {
            const contestToUsers = this.state.singleContest.contestToUsers;

            const contestWinners = contestToUsers.filter((contestant) => {
                if (contestant.flags) {
                    return contestant.flags.showWinner;
                }
                return false;
            });

            const {singleContest, isAfterDateTo} = this.state

            return (
                <Wrapper>
                    <ContestImage background={this.state.singleContest.imageUrl}/>
                    <ContestSection>
                        <TopBar link="/app/contests/" fixed="true"/>

                        <ContestsTags
                            className={this.state.singleContest.tag || this.state.singleContest.hashTag ? "" : "favourite-only"}>
                            {this.state.singleContest.tag && <TagName>{this.state.singleContest.tag}</TagName>}

                            {this.state.singleContest.hashTag && <TagName>#{this.state.singleContest.hashTag}</TagName>}

                            <FavouriteTag onClick={this.handleFavourite}>
                                <StarIcon className={this.state.isFavourite ? "favourite" : ""}/>
                            </FavouriteTag>
                        </ContestsTags>

                        <ContestTitle>
                            {singleContest.name}
                        </ContestTitle>

                        {singleContest.flags && singleContest.flags.showTimer && !isAfterDateTo &&
                        <TimeLeftCounter dateTo={this.state.singleContest.dateTo}/>
                        }

                        <ContestDescription>
                            <p><strong>Opis</strong></p>
                            <ReactMarkdown source={this.state.singleContest.description}/>
                        </ContestDescription>

                        {this.state.singleContest.type === 1 &&
                        <ContestHashtag>
                            #{this.state.singleContest.hashTag}
                        </ContestHashtag>
                        }

                        <ContestRules>
                            <p><strong>Zasady</strong></p>
                            <ReactMarkdown source={this.state.singleContest.rules}/>
                        </ContestRules>

                        {this.state.singleContest.type === 1 &&
                        //Miejsce na wprowadzenie nicka instagramowego, a także podgląd zdjęć
                        <div>
                            <Header>
                                Wprowadź <span>instagramowy</span> nick
                            </Header>

                            {!this.props.userProfile.instagram &&
                            <div>
                                <FieldGroup
                                    control={this.changeInstagramForm}
                                    render={({get, invalid}) => (
                                        <form onSubmit={this.handleInstagramSubmit}>

                                            <FieldControl
                                                name="instagram"
                                                render={TextInput}
                                                meta={{
                                                    label: "Nazwa użytkownika",
                                                    style: "simple",
                                                    minLength: 8,
                                                    padding: '0px',
                                                }}
                                            />

                                            <Button
                                                type="submit"
                                                disabled={invalid}
                                                title="Zapisz"
                                            />
                                        </form>
                                    )}
                                />

                                {this.state.error &&
                                <div className="error">
                                    <div>{this.state.error.message}</div>
                                </div>
                                }
                            </div>
                            }

                            {this.props.userProfile.instagram &&
                            <div>
                                Twój obecny nick to: {this.props.userProfile.instagram}, aby go zmienić,
                                przejdź do zakładki <StyledLink to='/app/more/account/#instagram'>Moje
                                Konto</StyledLink>.
                            </div>
                            }

                            <Snackbar
                                className="success"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={this.state.showInstagramSnackbar}
                                autoHideDuration={3000}
                                onClose={this.handleSnackbarClose}
                                message={<span>Pomyślnie zaktualizowano!</span>}
                            />
                        </div>
                        }

                        {this.state.singleContest.type === 2 &&
                        // Miejsce na wprowadzenie odpowiedzi
                        <div>
                            {this.state.showContestForm &&
                            <div>
                                <Header>
                                    Wprowadź odpowiedź na zadanie konkursowe
                                </Header>
                                <FieldGroup
                                    control={this.answerForm}
                                    render={({get, invalid}) => (
                                        <form onSubmit={this.handleAnswerSubmit}>

                                            <FieldControl
                                                name="answer"
                                                render={TextAreaInput}
                                                meta={{
                                                    label: "Twoja odpowiedź...",
                                                    style: "simple",
                                                    padding: '0px',
                                                }}
                                            />

                                            <FileInput
                                                name="answerFile"
                                                meta={{
                                                    label: "Wybierz plik...",
                                                    style: "simple",
                                                    padding: '0px',
                                                }}
                                                onChange={this.handleFileChange}
                                                accept="image/png, image/jpeg"
                                                multiple={false}
                                            />

                                            {/*Hidden input - fileinput managed*/}
                                            <FieldControl
                                                name="answerFile"
                                                render={TextInput}
                                                meta={{label: "Wybierz plik...", style: "bordered", hidden: true}}
                                            />

                                            <Button
                                                type="submit"
                                                disabled={!this.answerForm.value.answer && !this.answerForm.value.answerFile}
                                                title="Zapisz"
                                            />
                                        </form>
                                    )}
                                />

                                {this.state.error &&
                                <div className="error">
                                    <div>{this.state.error.message}</div>
                                </div>
                                }
                            </div>
                            }


                            <Snackbar
                                className="success"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={this.state.showAnswerSnackbar}
                                autoHideDuration={3000}
                                onClose={this.handleSnackbarClose}
                                message={<span>Zgłoszenie wysłano pomyślnie.</span>}
                            />
                        </div>
                        }

                        {this.state.isAfterDateTo &&
                        <div>
                            <Header>
                                Zwycięskie zgłoszenia
                            </Header>

                            {contestToUsers.length === 0 &&
                            <div>Ten konkurs został zakończony - wyniki już wkrótce!</div>
                            }

                            {contestToUsers.length > 0 &&
                            <div>
                                {contestWinners.map(item => {
                                    return (
                                        <ContestWinnerWrapper>
                                            <ContestWinnerAnswer>
                                                {item.answer}
                                            </ContestWinnerAnswer>
                                            <ContestWinnerImage>
                                                <img src={item.imageUrl} alt={item.answer}/>
                                            </ContestWinnerImage>
                                        </ContestWinnerWrapper>
                                    )
                                })}
                            </div>
                            }
                        </div>
                        }

                        <div>
                            <Header>
                                Ostatnie zgłoszenia
                            </Header>
                            <div>
                                {contestToUsers.map(item => {
                                    return (
                                        <ContestWinnerWrapper>
                                            <ContestWinnerAnswer>
                                                {item.answer}
                                            </ContestWinnerAnswer>
                                            <ContestWinnerImage>
                                                <img src={item.imageUrl} alt={item.answer}/>
                                            </ContestWinnerImage>
                                        </ContestWinnerWrapper>
                                    )
                                })}
                                {contestToUsers.length < 1 &&
                                <div>Nie ma jeszcze zaakceptowanych zgłoszeń w tym konkursie. Czekamy na
                                    Twoje!</div>
                                }
                            </div>
                        </div>
                    </ContestSection>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper>
                    <ContestSection>
                        <TopBar link="/app/contests/"/>
                        <SectionHeader header="Konkursy"/>
                        Coś poszło nie tak, przykro nam!
                    </ContestSection>
                </Wrapper>
            );
        }
    }
}

export default SingleContestPage;