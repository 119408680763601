import React from 'react';
import styled from "styled-components";
import TopBar from "components/TopBar";
import SectionHeader from "components/SectionHeader";
import axios from "axios";
import {Link} from "gatsby";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import theme from "assets/styles/theme";
import Loader from "components/Loader";
import TimeLeft from "components/TimeLeft"
import {checkNotificationPermission} from "services/pushManager"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 90px 15px;
    display: flex;
    flex-direction: column;
`

const QuizesSection = styled.div`

`

const QuizesWrapper = styled(Link)`
    width: 100%;
    background: url(${({ image }) => (image ? image : "")})
      no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 150px;
    margin-bottom: 2rem;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
    font-size: 2.3rem;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    }

    &:hover, &:focus {
        text-decoration: none;
    }
`

const QuizesTitle = styled.p`
    position: relative;
    margin: 1rem 0;
`

const StyledTabs = styled(Tabs)`
    margin: 2rem 0;

    .MuiTabs-indicator {
        background-color: ${theme.color.mainBlueColor};
    }
`

const StyledTab = styled(Tab)`
    font-size: 1.3rem !important;
    min-width: 40% !important;
    text-transform: none !important;
    opacity: 1 !important;
    font-family: inherit !important;
    border-bottom: 2px solid !important;

    &.Mui-selected {
        color: ${theme.color.mainBlueColor} !important;
        font-weight: bold !important;
    }
`

const QuizCategories = styled.div`
    position: absolute;
    font-size: 1.3rem;
    display: flex;
    width: 100%;
    left: 0;
    padding: 1.5rem;
    top: 0;
    flex-wrap: wrap;
`

const CategoryName = styled.div`
  padding: 0.7rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 15px;
  background-color: ${theme.color.mainBlueColor};
  box-shadow: 0 0 30px 0 rgba(0,48,255,0.8);
`

const TimeLeftCounter = styled(TimeLeft)`
  font-size: 1.3rem;
  color: #ffffff;
  margin: 0;
  z-index: 0;
  grid-row: 2 / -2;
  grid-column: 1 / -1;
`

class QuizesPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeCategoryIndex: 0,
            loading: true,
            error: false,
            quizesItems: [],
        }
    }

    componentDidMount() {
        this.fetchQuizes()

        setTimeout(() => {
            checkNotificationPermission(this.props.userProfile.accessToken)
        }, 3000)

        let activeCategoryIndex = window.history.state.activeCategoryIndex
        if (activeCategoryIndex) {
            this.setState({
                activeCategoryIndex: activeCategoryIndex,
            })
            window.history.state.activeCategoryIndex = null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
            this.fetchQuizes(this.props.id)
        }

    }

    fetchQuizes = () => {
        const { accessToken } = this.props.userProfile
        if (!accessToken) {
            return
        }

        this.setState({ loading: true })

        let params = {
            "access-token": accessToken,
            "expand": "questions",
            "sort": "-created_at",
        }

        axios
            .get(`${process.env.API_URL}/questionnaires`, { params })
            .then(quizesItems => {
                this.setState({
                    loading: false,
                    quizesItems: quizesItems.data,
                })
            })
            .catch(error => {
                this.setState({ loading: false, error: error })
            })
    }

    handleTabChange = (event, value) => {
        this.setState({
            activeCategoryIndex: value,
        })
    }

    handleTabChangeIndex = activeCategoryIndex => {
        this.setState({
            activeCategoryIndex,
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Wrapper>
                    <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader header="Quizy"/>
                    <Loader/>
                </Wrapper>
            )
        } else if (this.state.quizesItems.length > 0) {
            const activeCategoryIndex = this.state.activeCategoryIndex
            const quizCategory_1 = this.state.quizesItems.filter(item => {

                let today = new Date()
                let dateFrom = new Date(item.dateFrom)
                let dateTo = item.dateTo ? new Date(item.dateTo) : new Date(8640000000000000)

                let isAfterFrom = dateFrom < today
                let isBeforeTo = today < dateTo

                return item.categoryId === 1 && !item.isSolved && (isAfterFrom && isBeforeTo)
            })

            const quizCategory_2 = this.state.quizesItems.filter(item => {
                let today = new Date()
                let dateFrom = new Date(item.dateFrom)
                let dateTo = item.dateTo ? new Date(item.dateTo) : new Date(8640000000000000)

                let isAfterFrom = dateFrom < today
                let isBeforeTo = today < dateTo

                return item.categoryId === 2 && !item.isSolved && (isAfterFrom && isBeforeTo)
            })

            const quizCategory_3 = this.state.quizesItems.filter(item => {
                let today = new Date()
                let dateFrom = new Date(item.dateFrom)
                let dateTo = item.dateTo ? new Date(item.dateTo) : new Date(8640000000000000)

                let isAfterFrom = dateFrom < today
                let isBeforeTo = today < dateTo

                return item.categoryId === 3 && !item.isSolved && (isAfterFrom && isBeforeTo)
            })

            const quizCategory_4 = this.state.quizesItems.filter(item => {
                let today = new Date()
                let dateFrom = new Date(item.dateFrom)
                let dateTo = item.dateTo ? new Date(item.dateTo) : new Date(8640000000000000)

                let isAfterFrom = dateFrom < today
                let isAfterTo = dateTo < today

                return item.isSolved || (isAfterFrom && isAfterTo)
            })

            return (
                <Wrapper>
                    <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader header="Quizy"/>

                    {/*Categories:  ABC - 1, DIY - 2, Dopasowanie - 3 */}
                    {/*Quizy archiwalne - 4*/}
                    <div>
                        <StyledTabs value={activeCategoryIndex} variant="scrollable" onChange={this.handleTabChange}>
                            <StyledTab label="Quizy ABC"/>
                            <StyledTab label="Quizy DIY"/>
                            <StyledTab label="Dopasowanie"/>
                            <StyledTab label="Zarchiwizowane"/>
                        </StyledTabs>
                        <SwipeableViews index={activeCategoryIndex} onChangeIndex={this.handleTabChangeIndex}>
                            <QuizesSection>
                                {quizCategory_1.map(item => {
                                    // Show only quizes with questions
                                    if (item.questions.length > 0) {
                                        return (
                                            <QuizesWrapper to={`/app/quizes-quiz/${item.questionnaireId}/`}
                                                           key={item.questionnaireId} image={item.imageUrl}>
                                                <TimeLeftCounter dateTo={item.dateTo}/>
                                                <QuizesTitle>{item.name}</QuizesTitle>
                                            </QuizesWrapper>
                                        )
                                    }
                                    return null
                                })}

                                {quizCategory_1.length === 0 &&
                                <div>Obecnie w tej kategorii nie ma żadnych quizów. Sprawdź za jakiś czas.</div>
                                }
                            </QuizesSection>
                            <QuizesSection>
                                {quizCategory_2.map(item => {
                                    // Show only quizes with questions
                                    if (item.questions.length > 0) {
                                        return (
                                            <QuizesWrapper to={`/app/quizes-quiz/${item.questionnaireId}/`}
                                                           key={item.questionnaireId} image={item.imageUrl}>
                                                <TimeLeftCounter dateTo={item.dateTo}/>
                                                <QuizesTitle>{item.name}</QuizesTitle>
                                            </QuizesWrapper>
                                        )
                                    }
                                    return false
                                })}

                                {quizCategory_2.length === 0 &&
                                <div>Obecnie w tej kategorii nie ma żadnych quizów. Sprawdź za jakiś czas.</div>
                                }
                            </QuizesSection>
                            <QuizesSection>
                                {quizCategory_3.map(item => {
                                    // Show only quizes with questions
                                    if (item.questions.length > 0) {
                                        return (
                                            <QuizesWrapper to={`/app/quizes-quiz/${item.questionnaireId}/`}
                                                           key={item.questionnaireId} image={item.imageUrl}>
                                                <TimeLeftCounter dateTo={item.dateTo}/>
                                                <QuizesTitle>{item.name}</QuizesTitle>
                                            </QuizesWrapper>
                                        )
                                    }
                                    return false
                                })}

                                {quizCategory_3.length === 0 &&
                                <div>Obecnie w tej kategorii nie ma żadnych quizów. Sprawdź za jakiś czas.</div>
                                }
                            </QuizesSection>
                            <QuizesSection>
                                {quizCategory_4.map(item => {
                                    // Show only quizes with questions
                                    if (item.questions.length > 0) {
                                        return (
                                            <QuizesWrapper to={`/app/quizes-quiz/${item.questionnaireId}/`}
                                                           key={item.questionnaireId} image={item.imageUrl}>
                                                <QuizCategories>
                                                    {item.categoryId === 1 &&
                                                    <CategoryName>Quiz ABC</CategoryName>
                                                    }
                                                    {item.categoryId === 2 &&
                                                    <CategoryName>Quiz DIY</CategoryName>
                                                    }
                                                    {item.categoryId === 3 &&
                                                    <CategoryName>Dopasowanie</CategoryName>
                                                    }
                                                </QuizCategories>
                                                <QuizesTitle>{item.name}</QuizesTitle>
                                            </QuizesWrapper>
                                        )
                                    }
                                    return false
                                })}
                                {quizCategory_4.length === 0 &&
                                <div>Obecnie w tej kategorii nie ma żadnych quizów. Rozwiąż jakieś i sprawdź tutaj
                                    odpowiedzi!</div>
                                }
                            </QuizesSection>
                        </SwipeableViews>
                    </div>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper>
                    <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                    <SectionHeader header="Quizy"/>
                    Nie mamy żadnych quizów do pokazania. Kiedyś na pewno się pojawią!
                </Wrapper>
            )
        }
    }
}

export default QuizesPage

