import React from "react"
import {Router} from "@reach/router"
import PrivateRoute from "components/PrivateRoute"
import MainTemplate from "templates/Main/MainTemplate";
import Media from "react-media";
import Navbar from "components/Navbar";
import DashboardPage from "components/Pages/Dashboard";
import ContestsPage from "components/Pages/Contests";
import QuizesPage from "components/Pages/Quizes";
import KnowledgePage from "components/Pages/Knowledge";
import NewsPage from "components/Pages/News";
import MorePage from "components/Pages/Details";
import RewardsPage from "components/Pages/Rewards";
import SingleKnowledgePage from "components/Pages/Subpages/Knowledge-post";
import SingleNewsPage from "components/Pages/Subpages/News-post";
import AccountPage from "components/Pages/Subpages/Details-account";
import SaldoHistory from "../components/Pages/Subpages/SaldoHistory";
import InfoPage from "components/Pages/Subpages/Details-info";
import SingleQuizPage from "components/Pages/Subpages/Quizes-quiz";
import SingleContestPage from "components/Pages/Subpages/Contests-contest";
import ThankYouPage from "components/Pages/ThankYou";
import SingleRewardPage from "components/Pages/Subpages/Rewards-reward";
import RewardsOrderPage from "components/Pages/Subpages/Rewards-order";
import Recommend from "components/Pages/Subpages/Recommend";
import Contact from "components/Pages/Subpages/Contact";
import FaqPage from "components/Pages/Subpages/Faq";
import Favorites from "components/Pages/Favorites";

const Index = (props) => (
    <MainTemplate {...props}>
        <Router>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/dashboard/"
                          component={DashboardPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/contests/"
                          component={ContestsPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/contests-contest/:id/"
                          component={SingleContestPage}
                          updateProfile={props.updateProfile}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/quizes/"
                          component={QuizesPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/quizes-quiz/:id/"
                          component={SingleQuizPage}
                          updateProfile={props.updateProfile}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/knowledge/"
                          component={KnowledgePage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/knowledge-post/:id/"
                          component={SingleKnowledgePage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/news/"
                          component={NewsPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/news-post/:id/"
                          component={SingleNewsPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/more/"
                          component={MorePage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/more/account/"
                          setProfile={props.setProfile}
                          updateProfile={props.updateProfile}
                          component={AccountPage}/>
            <PrivateRoute user={props.user}
                          userProfile={props.userProfile}
                          path="/app/more/saldo/"
                          component={SaldoHistory}/>
            <PrivateRoute user={props.user}
                          userProfile={props.userProfile}
                          path="/app/more/faq/"
                          component={FaqPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/more/recommend/"
                          component={Recommend}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/more/contact/"
                          component={Contact}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/more/info/"
                          component={InfoPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/rewards/"
                          component={RewardsPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/rewards-reward/:id/"
                          component={SingleRewardPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/rewards-order/:id/"
                          component={RewardsOrderPage}
                          setProfile={props.setProfile}
                          updateProfile={props.updateProfile}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/thank-you/"
                          component={ThankYouPage}/>
            <PrivateRoute user={props.user}
                          userFirebaseData={props.userFirebaseData}
                          userProfile={props.userProfile}
                          path="/app/favorites/"
                          component={Favorites}/>
        </Router>

        <Media
            query="(max-width: 500px)"
            render={() => <Navbar userProfile={props.userProfile}/>}
        />
    </MainTemplate>
);

export default Index
