import React from "react"
import styled from "styled-components"
import HandIcon from "assets/images/svg/hand_icon.svg"
import TopBar from "components/TopBar"
import SectionHeader from "components/SectionHeader"
import ButtonLink from "../ButtonLink"

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 15px 15px 90px 15px;
    display: flex;
    flex-direction: column;
`

const ThankYouWrapper = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    height: 70vh;
    justify-self: center;
    flex-direction: column;
    text-align: center;
`

const SectionText = styled.div`
    font-size: 1.5rem;
    padding: 0 15px;
    margin-bottom: 0.5rem;
`

class ThankYouPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            welcomepack: false,
        }
    }

    componentDidMount() {
        let welcomepack = window.history.state.welcomepack
        if (welcomepack) {
            this.setState({
                welcomepack: welcomepack,
            })
        }
    }

    thankYouWelcomepack = () => {
        return (
            <>
                <SectionHeader header="Gratulujemy!"/>
                <SectionText>Już niedługo Twoje mankiety błysną limitowanymi spinkami Maker’s Mark.</SectionText>
                <SectionText>O statusie przesyłki powiadomimy Cię mailowo.</SectionText>
            </>
        )
    }

    thankYouReward = () => {
        return (
            <>
                <SectionHeader header="Dziękujemy!"/>
                <SectionText>Dziękujemy za zamówienie nagrody!</SectionText>
                <SectionText>Skontaktujemy się z Tobą mailowo.</SectionText>
            </>
        )
    }

    render() {
        return (
            <Wrapper>
                <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
                <ThankYouWrapper>
                    <HandIcon/>
                    {this.state.welcomepack ? this.thankYouWelcomepack() : this.thankYouReward()}
                    <p>
                        <ButtonLink title={"OK"}/>
                    </p>
                </ThankYouWrapper>
            </Wrapper>
        )
    }
}

export default ThankYouPage