import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import moment from "moment"
import "moment/locale/pl"

const TimeLeftInfo = styled.p`
  font-size: 1.3rem;
  color: #ffffff;
  margin: 0;
  z-index: 50;
`

const TimeLeft = props => {
    moment.locale("pl")

    if (props.dateTo) {
        return (
            <TimeLeftInfo className={props.className}>
                {props.text} {props.from ? moment(props.dateTo).fromNow() : moment(props.dateTo).toNow(true)}
            </TimeLeftInfo>
        )
    }
    return null
}

TimeLeft.defaultProps = {
    text: `do końca pozostało `,
    from: false,
}

TimeLeft.propTypes = {
    dateTo: PropTypes.instanceOf(Date),
    text: PropTypes.string,
    from: PropTypes.bool,
}

export default TimeLeft
