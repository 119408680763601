import axios from "axios"

export function setNotificationPermissions(newPermissions, accessToken) {
    let currentPermissions = getNotificationPermissions();
    console.log(currentPermissions, newPermissions)
    localStorage.setItem('notification-permissions', newPermissions);

    if (currentPermissions === 'granted') {
        if (newPermissions === 'denied') {
            getCurrentToken().then((token) => {
                if (token) {
                    revokeFromPush(token, accessToken);
                }
            });
        }

        currentPermissions = newPermissions;
    } else if (newPermissions === 'granted') {
        currentPermissions = requestNotificationPermission(accessToken);
    }

    return currentPermissions;
}

export function getNotificationPermissions() {
    if ('Notification' in window) {
        if (Notification.permission === 'granted') {
            if (localStorage.getItem('notification-permissions')) {
                return localStorage.getItem('notification-permissions');
            }

            return 'granted'
        } else {
            return Notification.permission ? Notification.permission : 'default';
        }
    } else {
        console.log('API Notyfikacji nie jest wspierane');
        return 'default'
    }
}

export function checkNotificationPermission(accessToken) {
    if ('Notification' in window) {
        if (Notification.permission === 'default') {
            requestNotificationPermission(accessToken);
        }
    } else {
        console.log('API Notyfikacji nie jest wspierane');
    }
}

function requestNotificationPermission(accessToken) {
    let permission = 'default';

    if ('Notification' in window) {
        if (Notification.permission === 'denied') {
            alert("Przed włączeniem powiadomień, zezwól na notyfikacje PUSH w swojej przeglądarce.");
        } else {
            Notification.requestPermission(function (choice) {
                if (choice === 'granted') {
                    getCurrentToken().then((token) => {
                        if (token) {
                            registerToPush(token, accessToken);
                            localStorage.setItem('notification-permissions', 'granted');
                        }
                    });
                } else {
                    localStorage.setItem('notification-permissions', 'denied');
                }

                permission = choice;
            });
        }
    }

    return permission === 'granted';
}

async function getCurrentToken() {
    if (typeof window !== "undefined") {
        const firebase = require("firebase");

        try {
            const messaging = firebase.messaging();
            return await messaging.getToken()
        } catch (error) {
            console.log(error)
        }

        return null;
    }
}

function registerToPush(userToken, accessToken) {
    // Call to API - to register User in google stuff
    // send user_token and topic_name (per env?)
    console.log("Sending: " + userToken);

    let params = {
        "access-token": accessToken,
    }

    axios
        .post(`${process.env.API_URL}/push-notification-subscribes`, {
            tokens: [userToken]
        }, {params})
        .then((response) => {
            alert("Zostałeś pomyślnie zapisany do powiadomień!")
        })
        .catch(error => {
            console.log(error)
        })
}

function revokeFromPush(userToken, accessToken) {
    // Call to API - to remove User in google stuff
    // send user_token
    console.log("Deleting: " + userToken);

    let params = {
        "access-token": accessToken,
    }

    axios
        .patch(`${process.env.API_URL}/push-notification-subscribes`, {
            tokens: [userToken]
        },{params})
        .then((response) => {
            alert("Zostałeś wypisany z powiadomień PUSH!")
        })
        .catch(error => {
            console.log(error)
        })
}