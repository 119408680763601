import React from "react"
import axios from "axios"
import styled from "styled-components"
import {Link} from "gatsby"
import Loader from "components/Loader"
import Pagination from "components/Pagination"
import SectionHeader from "components/SectionHeader"
import TopBar from "components/TopBar"
import {checkNotificationPermission} from "services/pushManager"

import FavButton from "../FavButton";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 90px 15px;
`


const NewsSection = styled.div`{

}`

const NewsLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  padding: 15px;
  display: flex;
  align-items: flex-end;
`

const NewsWrapper = styled.div`
  width: 100%;
  background: url(${({image}) => (image ? image : "")}) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 250px;
  margin-bottom: 2rem;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  font-size: 2.3rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:hover, &:focus {
    text-decoration: none;
  }
`

const NewsTitle = styled.p`
  position: relative;
  margin: 1rem 0;
`

class NewsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: false,
      newsItems: [],
      pagination: {},
    }

    this.onChangePage = this.onChangePage.bind(this)
    this.toggleFav = this.toggleFav.bind(this)
  }

  componentDidMount() {
    this.fetchNews()

    setTimeout(() => {
      checkNotificationPermission(this.props.userProfile.accessToken)
    }, 3000)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.userProfile.accessToken && this.props.userProfile.accessToken) {
      this.fetchNews(this.props.id)
    }
  }

  onChangePage(pageOfItems) {
    this.fetchNews(pageOfItems)
  }

  fetchNews = (page = 1) => {
    if (!this.props.userProfile.accessToken) {
      return
    }

    let params = {
      "access-token": this.props.userProfile.accessToken,
      "sort": "-created_at",
      "page": page,
    }

    this.setState({loading: true})
    axios
      .get(`${process.env.API_URL}/news`, {params})
      .then(response => {
        this.setState({
          loading: false,
          newsItems: response.data.items,
          pagination: {
            currentPage: parseInt(response.headers["x-pagination-current-page"]),
            pageCount: parseInt(response.headers["x-pagination-page-count"]),
            itemsPerPage: parseInt(response.headers["x-pagination-per-page"]),
            totalItems: parseInt(response.headers["x-pagination-total-count"]),
          },
        })
      })
      .catch(error => {
        this.setState({loading: false, error: error})
      })
  }

  toggleFav = (ev) =>{
    const payload = JSON.parse(ev.currentTarget.value)
    const {id, fav} = payload

    this.setState({loading: true})

    axios({
      method: fav ? 'delete' : 'post',
      url: `${process.env.API_URL}/news/${id}/favourite`,
      params: {"access-token": this.props.userProfile.accessToken}
    })
      .then(response => {
        const news = [...this.state.newsItems]
        const index = this.state.newsItems.findIndex((el) => el.articleId === id);
        news[index] = {
          ...news[index],
          favourite: !fav
        };
        this.setState({
          loading: false,
          newsItems: news
        })
      })
      .catch(error => {
        this.setState({loading: false, error: error})
      })
  }

  render() {
    const {pagination: {currentPage, pageCount}} = this.state

    if (this.state.loading) {
      return (
        <Wrapper>
          <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
          <SectionHeader header="Aktualności"/>
          <Loader/>
        </Wrapper>
      )
    } else if (this.state.newsItems.length > 0) {
      return (
        <Wrapper>
          <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
          <SectionHeader header="Aktualności"/>

          <NewsSection>
            {this.state.newsItems.map(item => {
              return <NewsWrapper key={item.articleId} image={item.imageUrl}>
                <FavButton
                  favorite={item.favourite}
                  value={JSON.stringify({id:item.articleId,fav:item.favourite})}
                  onClick={(ev) => this.toggleFav(ev)}
                />
                <NewsLink to={`/app/news-post/${item.articleId}/`}>
                  <NewsTitle>{item.title}</NewsTitle>
                </NewsLink>
              </NewsWrapper>
            })}
          </NewsSection>

          <Pagination currentPage={currentPage} pageCount={pageCount} range={3}
                      onChangePage={this.onChangePage}/>

        </Wrapper>
      )
    } else {
      return (
        <Wrapper>
          <TopBar link="/app/dashboard/" points={this.props.userProfile.saldo}/>
          <SectionHeader header="Aktualności"/>
          Nie mamy żadnych aktualności do pokazania. Kiedyś na pewno się
          pojawią!
        </Wrapper>
      )
    }
  }
}

export default NewsPage

