import React from "react"
import {graphql, StaticQuery} from "gatsby"
import styled from "styled-components"
import Button from "components/Button"
import "assets/styles/loaders.min.css"
import {navigate} from "gatsby-link";

const PopupWrapper = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
`

const PopupContent = styled.div`
    width: 90%;
    background: white;
    border-radius: 15px;
    color: black;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    text-align: center;
`

const PopupImage = styled.div`
    background: url(${({background}) => (background ? background : "")})
      no-repeat;
    min-height: 20vh;
    background-position: center top;
    background-size: cover;
    border-radius: 14px 14px 35px 35px;
`

const PopupHeader = styled.div`
    font-size: 2rem;
    font-weight: bold;
    padding: 0 15px;
    margin-top: 2rem;
`

const PopupText = styled.div`
    padding: 0 15px;
    margin-top: 2rem;
`

const PopupButtons = styled.div`
    margin: 2rem 0;
`

class VerifiedPopup extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpened: false,
        }
    }

    componentDidMount() {
        this.checkIfCanOpenPopup()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userProfile && this.props.userProfile) {
            this.checkIfCanOpenPopup()
        }
    }

    checkIfCanOpenPopup = () => {
        if (!this.props.userProfile.isVerified) {
            this.setState({
                isOpened: true,
            })
        }
    }

    handleClosePopup = () => {
        this.setState({
            isOpened: false,
        }, () => {
            navigate(`app/more/faq/`);
        })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                {
                    loginBackground: file(relativePath: { eq: "login.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                render={data => {
                    if (this.state.isOpened) {
                        return (
                            <PopupWrapper>
                                <PopupContent>
                                    <PopupImage background={data.loginBackground.childImageSharp.fluid.src}/>
                                    <PopupHeader>
                                        Weryfikacja konta
                                    </PopupHeader>
                                    <PopupText>
                                        Gratulacje! Masz już punkty, ale bez zgody właściciela baru nie możesz z nich
                                        korzystać. Dostarcz ją jak najszybciej!
                                    </PopupText>
                                    <PopupText>
                                        Instrukcję znajdziesz w FAQ.
                                    </PopupText>
                                    <PopupButtons>
                                        <Button onClick={this.handleClosePopup} title='Sprawdź'/>
                                    </PopupButtons>
                                </PopupContent>
                            </PopupWrapper>
                        )
                    } else {
                        return null
                    }
                }}
            />
        )
    }
}

export default VerifiedPopup
